import { SplitColumnsContainer } from '@startlibs/components'
import { Combobox, FormValue, SimpleCheckbox, TextInput, useFormValue } from '@startlibs/form'
import { getColor } from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';
import { Card, SectionHeading } from '../../components/PageLayout';
import { LOCAL_PHYSICIAN } from '../../enums/ContactRelationType'
import { ConfirmEmailInput } from '../../components/ConfirmEmailInput'
import { getFormattedStates } from '../PatientAndContactDetails';
import { ComboboxContainer } from './ContactDetails';
import StyledTelInput from '../../components/StyledTelInput';

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

const VIEWPORT_MIN_WIDTH = 500

export const LocalPhysician = ({ readOnly, isAdmin, form, countryList, showSelects,
  localphysicianPhoneNumberValid, setLocalphysicianPhoneNumberValid,
  localphysicianFaxNumberValid, setLocalphysicianFaxNumberValid 
  }) => {

  const [relationType] = useFormValue('contactDetails.contactRelationType')

  if (relationType === LOCAL_PHYSICIAN) {
    return null
  }

  return <>
    <SectionHeading>
      <h3>Local physician</h3>
      <p>
        This physician can be requested to take action on this case and may
        receive a copy of any resulting reports.
      </p>
    </SectionHeading>
    <Card css="padding-bottom:1.5rem;">
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">

        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.fullName.first"
          label="First name"
          placeholder="Ex: John"
          maxLength={255}
        />

        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.fullName.last"
          label="Last name"
          placeholder="Ex: Doe"
          maxLength={255}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.institution"
          label="Institution"
          placeholder="Ex: Purview"
          maxLength={255}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        {showSelects ? <>
          <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
            disabled={readOnly}
            className="combobox fs-exclude"
            placeholder="Please select country"
            label={"Country"}
            path='localPhysician.address.country'
            options={countryList}
            onChange={(val) => {
              var states = getFormattedStates(val)
              if (states.length > 0) {
                if (states.findIndex(x => x === form.getValue('localPhysician.address.state')) == -1) {
                  form.setValue('localPhysician.address.state', null)
                }
              }
            }}
          /></ComboboxContainer>
          <FormValue path="localPhysician.address.country">{country =>
            <ComboboxContainer readOnly={readOnly} className='fs-exclude'>
              <Combobox
                disabled={readOnly}
                className="combobox fs-exclude"
                placeholder="Please select state"
                label={"State/Province"}
                path='localPhysician.address.state'
                options={getFormattedStates(country)}
              // onChange={(val) => setRegion(val)}
              />
            </ComboboxContainer>
          }</FormValue>
        </> : <>
          <TextInput
            disabled={readOnly}
            type="text"
            path="localPhysician.address.country"
            label="Country"
            placeholder="Ex: USA"
            maxLength={255}
          // mandatory
          />
          <TextInput
            disabled={readOnly}
            type="text"
            path="localPhysician.address.state"
            label="State"
            placeholder="Ex: New York"
            maxLength={255}
          // mandatory
          />
        </>}
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.address.city"
          label="City"
          placeholder="Ex: New York"
          maxLength={255}
        // mandatory
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.address.zipCode"
          label="Postal code"
          placeholder="Ex: 12345"
          maxLength={255}
        // mandatory
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.address.address"
          label="Street address - Line 1"
          placeholder="Ex: 001 Street Name"
          maxLength={255}
        />
        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.address.address2"
          label="Street address - Line 2"
          placeholder="Ex: PO BOX 001"
          maxLength={255}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.email"
          label="Email address"
          placeholder="Ex: physicians@email.com"
          maxLength={255}
        />
        <ConfirmEmailInput
          disabled={readOnly}
          type="text"
          path="localPhysician.confirmEmail"
          confirmingPath="localPhysician.email"
          label="Confirm email"
          placeholder="Ex: physicians@email.com"
          maxLength={255}
          mandatory={false}
        />
      </SplitColumnsContainer>
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">

        <StyledTelInput
          path="localPhysician.phoneNumber"
          label="Phone number"
          hasErrors={form.hasErrors()}
          disabled={readOnly}
          placeholder="(201) 555-0123"
          initOptions={{
            initialCountry: "us",
            separateDialCode: false,
            autoPlaceholder: "aggressive",
            formatOnDisplay: true,
            placeholderNumberType: "FIXED_LINE_OR_MOBILE",
            nationalMode: false,
            countryOrder: ["us"]
          }}
          tabIndex={1}
          isValid={localphysicianPhoneNumberValid}
          setIsValid={setLocalphysicianPhoneNumberValid}
        />
        <StyledTelInput
          path="localPhysician.faxNumber"
          hasErrors={form.hasErrors()}
          label="Fax number"
          disabled={readOnly}
          placeholder="(201) 555-0123"
          initOptions={{
            initialCountry: "us",
            separateDialCode: false,
            autoPlaceholder: "aggressive",
            formatOnDisplay: true,
            placeholderNumberType: "FIXED_LINE_OR_MOBILE",
            nationalMode: false,
            countryOrder: ["us"]
          }}
          tabIndex={1}
          isValid={localphysicianFaxNumberValid}
          setIsValid={setLocalphysicianFaxNumberValid}
        />
        {isAdmin && <TextInput
          disabled={readOnly}
          type="text"
          path="localPhysician.npi"
          label="NPI"
          placeholder="Ex: 1234567890"
          maxLength={255}
        // mandatory
        />}

      </SplitColumnsContainer>
      <FormValue path="localPhysician.acceptContact">{acceptContact =>
        acceptContact !== null && <>
          {isAdmin && <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            <SimpleCheckbox
              disabled={readOnly}
              path="localPhysician.acceptContact"
              label={<span css="font-size:13px;">This physician has been made aware of this case, and can be contacted as necessary</span>}
            />
          </SplitColumnsContainer>}
        </>}
      </FormValue>
    </Card>
  </>
}
