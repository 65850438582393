import React, { useState } from 'react'
import {Errors, WithForm} from '@startlibs/form'
import {useEnsureRef, useToggle} from '@startlibs/core'
import {useNotification} from '../components/Notifications'
import {
  booleanRequired,
  buildValidation,
  confirmEmails,
  emailValidation,
  onlyIfEqual,
  onlyIfNotEqual,
  optional,
  required
} from '../utils/validation'
import {PatientDetails} from './forms/PatientDetails'
import _ from 'lodash/fp'
import {callIfFunction, formatDate, parseDate} from '@startlibs/utils'
import {LOCAL_PHYSICIAN, OTHER, PATIENT, REF_PHYSICIAN} from '../enums/ContactRelationType'
import {ContactDetails} from './forms/ContactDetails'
import {ReferringPhysician} from './forms/ReferringPhysician'
import {InsuranceDetails} from './forms/InsuranceDetails'
import {PrimaryConcern} from './forms/PrimaryConcern'
import {checkInsurance, INSURANCE_REQUIRED_KEYS, removeExtraInsuranceFields} from './forms/utils'
import {AcceptTermsCheckbox, requiredTerms} from './components/AcceptTermsCheckbox'
import {IfLocale, useIsLocale} from '../hocs/IfLocale'
import { CaseReconciliation } from './forms/CaseReconciliation'
import { lazyProviderInfo } from '../components/WithProvider'
import { CountryRegionData } from 'react-country-region-selector'
import { LocalPhysician } from './forms/LocalPhysician'

const onFailure = (e) => {
  console.log(e)
}

export const getFormattedStates = (country) => {
  var adjustedCountry = country == 'USA' ? 'United States' : country
  const selectedCountryData = CountryRegionData.find(data => {
    return data[0] === adjustedCountry || data[1] === adjustedCountry
  })
  const stateOptions = selectedCountryData 
    ? selectedCountryData[2].split("|").map(state => state.split("~")[0])
    : []
    stateOptions.unshift('')
  return stateOptions
}

const dobTransform = _.update('dob', (d) => formatDate(d, "yyyy-MM-dd"))
const dobParse = _.update('dob', (d) => d && parseDate(d, null, "yyyy-MM-dd"))

const alreadyHasEmail = (path, caseRequest, props) => caseRequest && _.get(path, caseRequest) === _.get(path, props)


export const PatientAndContactDetails = ({
                                           scrollToContact, scrollToPrimaryConcern, hideErrors, onChange,
                                           action, isPatient, isNurse, isRefPhysician, isPatientCreating, readOnly, onSuccess, onFailure, children, formRef, caseRequest, isAdmin, acceptedJurisdictions = null,
                                           values = {
    contactDetails: {contactRelationType: isRefPhysician ? REF_PHYSICIAN : PATIENT},
    patientInfo: {insurances:[]},
    referringPhysician: isPatientCreating ? {acceptContact: false} : {},
    caseInfo: {hasNewDiagnosisChangedTreatmentOrRecommendation: null},
    // caseInfo: {},
    categories:[]
  }
                                         }) => {

                                          

  const [contactPhoneNumberValid, setContactPhoneNumberValid] = useState(true)
  const [contactFaxNumberValid, setContactFaxNumberValid] = useState(true)
  const [refphysicianPhoneNumberValid, setRefphysicianPhoneNumberValid] = useState(true)
  const [refphysicianFaxNumberValid, setRefphysicianFaxNumberValid] = useState(true)
  const [localphysicianPhoneNumberValid, setLocalphysicianPhoneNumberValid] = useState(true)
  const [localphysicianFaxNumberValid, setLocalphysicianFaxNumberValid] = useState(true)

  const ensuredFormRef = useEnsureRef(formRef)
  const providerInfo = lazyProviderInfo.read()

  // useSetConfirmExitPage(() => !readOnly && ensuredFormRef.current.confirm())

  const [, setNotification] = useNotification()

  const saveAndExitLoading = useToggle()

  // Country and Regions data using react-country-region-selector
  // Documentation: https://country-regions.github.io/react-country-region-selector/
  // insert an empty option at the beginning
  const countryList = CountryRegionData.map(country => country[0])
  // Find the index of 'United States'
  const usIndex = countryList.findIndex(country => country === 'United States');
  if (usIndex !== -1) {
    // Remove 'United States' from its current position
    const usItem = countryList.splice(usIndex, 1)[0];
    // Add 'United States' to the start of the array
    countryList.unshift(usItem);
  }
  countryList.unshift('')
  
  const patientCountryAndStateEmpty = caseRequest?.patientInfo?.address?.country == '' && caseRequest?.patientInfo?.address?.state == ''
  const patientCountryAndStateNotExists= caseRequest?.patientInfo?.address == null
  const patientCountryAndStateNull= caseRequest?.patientInfo?.address?.country == null && caseRequest?.patientInfo?.address?.state == null
  const patientCountry = caseRequest?.patientInfo?.address?.country === 'USA' ? 'US' : caseRequest?.patientInfo?.address?.country
  const patientState = caseRequest?.patientInfo?.address?.state
  const patientCountryInList = patientCountry && CountryRegionData.find(country => country[0] === patientCountry || country[1] === patientCountry)
  const patientStateInList = patientState && patientCountryInList && patientCountryInList[2].split("|")
    .find(state => state.split("~")[0] === patientState || state.split("~")[1] === patientState)
  const fullCountryName = patientCountryInList && patientCountryInList[0]
  const fullStateName = patientStateInList && patientStateInList.split("~")[0]
  const showSelects = (!!fullCountryName && !!fullStateName) || (patientCountryAndStateEmpty) || (patientCountryAndStateNotExists) || (patientCountryAndStateNull)

  const contactCountryAndStateEmpty = caseRequest?.contactDetails?.address?.country == '' && caseRequest?.contactDetails?.address?.state == ''
  const contactCountryAndStateNotExists= caseRequest?.contactDetails?.address == null 
  const contactCountryAndStateNull= caseRequest?.contactDetails?.address?.country == null && caseRequest?.contactDetails?.address?.state == null
  const contactCountry = caseRequest?.contactDetails?.address?.country === 'USA' ? 'US' : caseRequest?.contactDetails?.address?.country
  const contactState = caseRequest?.contactDetails?.address?.state
  const contactCountryInList = contactCountry && CountryRegionData.find(country => country[0] === contactCountry || country[1] === contactCountry)
  const contactStateInList = contactState && contactCountryInList && contactCountryInList[2].split("|")
    .find(state => state.split("~")[0] === contactState || state.split("~")[1] === contactState)
  const fullContactCountryName = contactCountryInList && contactCountryInList[0]
  const fullContactStateName = contactStateInList && contactStateInList.split("~")[0]
  const showContactSelects = (!!fullContactCountryName && !!fullContactStateName) || (contactCountryAndStateNotExists) || (contactCountryAndStateEmpty) || (contactCountryAndStateNull)
  
  
  const phyCountryAndStateEmpty = caseRequest?.referringPhysician?.address?.country == '' && caseRequest?.referringPhysician?.address?.state == ''
  const phyCountryAndStateNotExists= caseRequest?.referringPhysician?.address == null
  const phyCountryAndStateNull= caseRequest?.referringPhysician?.address?.country == null && caseRequest?.referringPhysician?.address?.state == null
  const phyCountry = caseRequest?.referringPhysician?.address?.country === 'USA' ? 'US' : caseRequest?.referringPhysician?.address?.country
  const phyState = caseRequest?.referringPhysician?.address?.state
  const phyCountryInList = phyCountry && CountryRegionData.find(country => country[0] === phyCountry || country[1] === phyCountry)
  const phyStateInList = phyState && phyCountryInList && phyCountryInList[2].split("|")
    .find(state => state.split("~")[0] === phyState || state.split("~")[1] === phyState)
  const fullPhyCountryName = phyCountryInList && phyCountryInList[0] 
  const fullPhyStateName = phyStateInList && phyStateInList.split("~")[0]
  const showPhySelects = (!!fullPhyCountryName && !!fullPhyStateName) || (phyCountryAndStateEmpty) || (phyCountryAndStateNotExists) || (phyCountryAndStateNull)
  const referringPhysicianLastName = caseRequest?.referringPhysician?.fullName?.last
  const localPhysicianLastName = caseRequest?.localPhysician?.fullName?.last
  
  const updatedValues = _.flow(
    !!fullCountryName ? _.set('patientInfo.address.country', fullCountryName) : _.identity,
    !!fullStateName ? _.set('patientInfo.address.state', fullStateName) : _.identity,
    !!fullContactCountryName ? _.set('contactDetails.address.country', fullContactCountryName) : _.identity,
    !!fullContactStateName ? _.set('contactDetails.address.state', fullContactStateName) : _.identity,
    !!fullPhyCountryName ? _.set('referringPhysician.address.country', fullPhyCountryName) : _.identity,
    !!fullPhyStateName ? _.set('referringPhysician.address.state', fullPhyStateName) : _.identity,
    referringPhysicianLastName === ' ' ? _.set('referringPhysician.fullName.last', '') : _.identity,
    localPhysicianLastName === ' ' ? _.set('localPhysician.fullName.last', '') : _.identity,
    _.update('patientInfo.insurances',(insurances) => insurances?.length > 0 ? insurances : [])
  )
  const transform = _.flow(
    (values) => values.contactDetails.contactRelationType === REF_PHYSICIAN ? _.set('referringPhysician', {}, values) : values,
    (values) => values.contactDetails.contactRelationType === LOCAL_PHYSICIAN ? _.set('localPhysician', {}, values) : values,
    _.update('patientInfo.dob', (date) => formatDate(new Date(date), "MM-dd-yyyy")),
    _.update('patientInfo.insurances', (insurances) => insurances?.filter((insurance,i) => !checkInsurance(insurance,i,true).length)),
    _.update('patientInfo.insurances', (insurances) => insurances.map(removeExtraInsuranceFields)),
    (values) => values?.patientInfo?.alternateAddress === "" ? _.unset('patientInfo.alternateAddress', values) : values,
    (values) => values.caseInfo.hasBeenTreatedOrSurgeryBefore === false 
      ?  _.update('caseInfo.treatedOrSurgeryBeforeDescription', () => '',values)
      : values
  )

  const isLegal = useIsLocale({contains:'LEGAL'})

  const preValidation = buildValidation({
    'categories': (categories) => categories && categories.find(_.isString) && "Please select a valid category from the list." ,
    'patientInfo.firstName': [required],
    'patientInfo.lastName': [required],
    'patientInfo.dob': [required, (d) => d === -1 && "Invalid date"],
    'patientInfo.gender': [required],
    'patientInfo.address.country': [required],
    'patientInfo.address.state': [required],
    'patientInfo.address.city': [required],
    'patientInfo.address.address': [required],
    'patientInfo.address.zipCode': [required],
    'contactDetails.contactRelationType': [required],
    'contactDetails.fullName.first': [onlyIfNotEqual('contactDetails.contactRelationType', PATIENT, required)],
    'contactDetails.fullName.last': [onlyIfNotEqual('contactDetails.contactRelationType', PATIENT, required)],
    'contactDetails.otherRelationType': [onlyIfEqual('contactDetails.contactRelationType', OTHER, required)],
    'contactDetails.email': [required, emailValidation],
    'contactDetails.confirmEmail': [(confirm, k, props) => !alreadyHasEmail('contactDetails.email', caseRequest, props) && confirmEmails('contactDetails.email')(confirm, k, props)],
    // 'contactDetails.phoneNumber': [required],
    // phone number is required and must only contain numbers and symbols but no letter
    'contactDetails.phoneNumber': [required, (v) => v && v.match(/^[0-9\-\+\(\)\s]+$/) && contactPhoneNumberValid ? undefined : "Please enter a valid phone number"],
    'contactDetails.faxNumber': [
      onlyIfEqual('contactDetails.contactRelationType', REF_PHYSICIAN, (v) => (
        (v && v.match(/^[0-9\-\+\(\)\s]+$/) && contactFaxNumberValid) || v === "" || !v) ? undefined : "Please enter a valid fax number"),
      onlyIfEqual('contactDetails.contactRelationType', LOCAL_PHYSICIAN, (v) => (
        (v && v.match(/^[0-9\-\+\(\)\s]+$/) && contactFaxNumberValid) || v === "" || !v) ? undefined : "Please enter a valid fax number")
    ],
    'contactDetails.prefCommunicationMode': [required],
    // 'contactDetails.prefContactTime': [required],
    'referringPhysician.email': [onlyIfNotEqual('contactDetails.contactRelationType', REF_PHYSICIAN, optional(emailValidation))],
    'referringPhysician.confirmEmail': [onlyIfNotEqual('contactDetails.contactRelationType', REF_PHYSICIAN, (v, k, props) => !alreadyHasEmail('referringPhysician.email', caseRequest, props) && confirmEmails('referringPhysician.email')(v, k, props))],
    'caseInfo.clinicalSummary': [required],
    'caseInfo.intakeDiagnosis': isLegal ? [] : values?.caseInfo?.intakeDiagnosis !== null ? [required] : [],
    'caseInfo.hasBeenTreatedOrSurgeryBefore': isLegal ? [] : [booleanRequired],
    'acceptedTerms': isPatient && requiredTerms(caseRequest),
    "referringPhysician.phoneNumber" : (v) => ((v && v.match(/^[0-9\-\+\(\)\s]+$/) && refphysicianPhoneNumberValid) || !v) ? undefined : "Please enter a valid phone number",
    "referringPhysician.faxNumber" : (v) => ((v && v.match(/^[0-9\-\+\(\)\s]+$/) && refphysicianFaxNumberValid) || !v) ? undefined : "Please enter a valid fax number",
    "localPhysician.phoneNumber" : (v) => ((v && v.match(/^[0-9\-\+\(\)\s]+$/) && localphysicianPhoneNumberValid) || !v) ? undefined : "Please enter a valid phone number",
    "localPhysician.faxNumber" : (v) => ((v && v.match(/^[0-9\-\+\(\)\s]+$/) && localphysicianFaxNumberValid) || !v) ? undefined : "Please enter a valid fax number",
    'localPhysician.email': [onlyIfNotEqual('contactDetails.contactRelationType', LOCAL_PHYSICIAN, optional(emailValidation))],
    'localPhysician.confirmEmail': [onlyIfNotEqual('contactDetails.contactRelationType', LOCAL_PHYSICIAN, (v, k, props) => !alreadyHasEmail('localPhysician.email', caseRequest, props) && confirmEmails('localPhysician.email')(v, k, props))],
  }, ({patientInfo: {insurances}}) => {
    if (insurances && insurances.length) {
      const errorsListList = insurances.map((insurance,i) => checkInsurance(insurance,i))
      const hasOneSuccess = errorsListList.find((list, i) => i === 0 && !list.length)
      return {
        ..._.fromPairs(_.flatten(errorsListList.filter(list => hasOneSuccess ? list.length < INSURANCE_REQUIRED_KEYS.length : true)))
      }
    }
  })

  return <WithForm
    alwaysSave
    onChange={onChange}
    ref={ensuredFormRef}
    transform={transform}
    action={action}
    onSuccess={onSuccess}
    onFailure={onFailure}
    preValidation={preValidation}
    // values={fixMedicareInsideInsurances(values)}
    values={updatedValues(values)}
  >{form => <>
    {/* <PatientDetails isAdmin={isAdmin} readOnly={readOnly} country={country} region={region} setCountry={setCountry} setRegion={setRegion} form={form} countryList={countryList} showSelects={showSelects}/> */}
    <PatientDetails isAdmin={isAdmin} readOnly={readOnly} form={form} countryList={countryList} showSelects={showSelects} 
      acceptedJurisdictions={isAdmin ? acceptedJurisdictions : null}
      isPatientCreating={isPatientCreating}
    />
    <ContactDetails 
      scrollTo={scrollToContact} readOnly={readOnly} form={form} countryList={countryList} showSelects={showContactSelects} 
      isPatientCreating={isPatientCreating} caseRequest={caseRequest}
      contactPhoneNumberValid={contactPhoneNumberValid} setContactPhoneNumberValid={setContactPhoneNumberValid}
      contactFaxNumberValid={contactFaxNumberValid} setContactFaxNumberValid={setContactFaxNumberValid}      
    />
    <IfLocale not contains='LEGAL'>
      <ReferringPhysician readOnly={readOnly} isAdmin={isAdmin} form={form} countryList={countryList} showSelects={showPhySelects}
        refphysicianPhoneNumberValid={refphysicianPhoneNumberValid} setRefphysicianPhoneNumberValid={setRefphysicianPhoneNumberValid}
        refphysicianFaxNumberValid={refphysicianFaxNumberValid} setRefphysicianFaxNumberValid={setRefphysicianFaxNumberValid}
      />
      <LocalPhysician readOnly={readOnly} isAdmin={isAdmin} form={form} countryList={countryList} showSelects={showPhySelects}
        localphysicianPhoneNumberValid={localphysicianPhoneNumberValid} setLocalphysicianPhoneNumberValid={setLocalphysicianPhoneNumberValid}
        localphysicianFaxNumberValid={localphysicianFaxNumberValid} setLocalphysicianFaxNumberValid={setLocalphysicianFaxNumberValid}
      />
      <InsuranceDetails readOnly={readOnly} form={form}/>
    </IfLocale>
    <PrimaryConcern readOnly={readOnly} scrollTo={scrollToPrimaryConcern}/>
    <IfLocale not contains='LEGAL'>
      {providerInfo?.ehrHl7Enabled === true && isAdmin && caseRequest === undefined && <CaseReconciliation/>}
    </IfLocale>
    {isPatient && !readOnly && <AcceptTermsCheckbox caseRequest={caseRequest}/>}

    {!hideErrors && <Errors/>}
    {callIfFunction(children, form)}
  </>}</WithForm>
}
