import { TextInput } from '@startlibs/form'
import React from 'react'
import { SplitColumnsContainer } from '@startlibs/components';
import { IntakeFormCard } from './PatientDetailsNew';
import { isMobile } from '@startlibs/utils';

export const CaseReconciliationNew = ({ title, subTitle, NavigationButtons }) => {

  const mobile = isMobile()

  return <>
    <IntakeFormCard>
      <div>
        {title && <h3>{title}</h3>}
        {subTitle && <p>{subTitle}</p>}
        {/* <p>Insert the information below to export this case to your EHR system</p> */}
        {mobile ? <>
          <TextInput
            label="Medical Record Number"
            helpText="(Patient Identification Number)"
            path="caseInfo.ehrInfo.mrn"
          />
          <TextInput
            label="Order Number"
            path="caseInfo.ehrInfo.orderNumber"
          />
        </>
          : <SplitColumnsContainer >
            <TextInput
              label="Medical Record Number"
              helpText="(Patient Identification Number)"
              css="min-width: 400px;"
              path="caseInfo.ehrInfo.mrn"
            />
            <TextInput
              label="Order Number"
              path="caseInfo.ehrInfo.orderNumber"
            />
          </SplitColumnsContainer>
        }
      </div>
      <NavigationButtons />
    </IntakeFormCard>
  </>
}