import { Errors } from '@startlibs/form'
import React from 'react'
import { getColor, isMobile } from '@startlibs/utils';
import { Button, SplitColumnsContainer, Icon } from '@startlibs/components';
import styled, { css } from 'styled-components';
import _ from 'lodash/fp'
import { CategoriesBox, useEditCategories } from '../../admin/experts/hooks/useEditCategories';
import { IntakeFormCard } from './PatientDetailsNew';

const PriorityButton = styled(Button)`
  box-shadow: none;
  width: 100%;
  justify-content: flex-start;
  ${props => props.isPriority && css`
    background: ${getColor('lightYellow')};
    color: ${getColor('warning')};
    :before {
      border: none;
    }
    ${Icon} {
      color: ${getColor('warning')};
    }
    :hover {
      opacity: 75%;
      background: ${getColor('lightYellow')};
    }
  `}
`

export const CaseSettingsNew = ({ title, subTitle, NavigationButtons, caseRequest, setCaseRequest, lazyCategories, form }) => {

  const mobile = isMobile()
  const toggleAsPriority = () => setCaseRequest(_.update('markAsPriority', (v) => v ? null : true))

  return <>
    <IntakeFormCard>
      <div>
        {title && <h3>{title}</h3>}
        {subTitle && <p>{subTitle}</p>}
        {mobile ? 
          <>
            <CategoriesBox lazyCategories={lazyCategories} form={form}
              minWidth={"320px"}
            />

            <div>
              <label style={{ display: 'block', fontSize: '14px', fontWeight: 600, marginBottom: '0.5rem' }}>Priority</label>
              <PriorityButton
                outline
                icon={caseRequest.markedAsPriority ? "star" : "star-line"}
                onClick={toggleAsPriority}
                isPriority={caseRequest.markedAsPriority}
              >
                {caseRequest.markedAsPriority ? "Remove as priority" : "Mark as priority"}
              </PriorityButton>
            </div>

          </> 
          : <SplitColumnsContainer>

            <CategoriesBox lazyCategories={lazyCategories} form={form}
              minWidth={"320px"}
            />

            <div>
              <label style={{ display: 'block', fontSize: '14px', fontWeight: 600, marginBottom: '0.5rem' }}>Priority</label>
              <PriorityButton
                outline
                icon={caseRequest.markedAsPriority ? "star" : "star-line"}
                onClick={toggleAsPriority}
                isPriority={caseRequest.markedAsPriority}
              >
                {caseRequest.markedAsPriority ? "Remove as priority" : "Mark as priority"}
              </PriorityButton>
            </div>
          </SplitColumnsContainer>
        }
      </div>
      <Errors path={'categories'} />
      <NavigationButtons />
    </IntakeFormCard>
  </>

}