import React from 'react'
import {PRIVATE, MEDICARE} from '../../enums/InsuranceType'
import _ from 'lodash/fp'
import {required} from '../../utils/validation'
import {OTHER, PATIENT, REF_PHYSICIAN} from '../../enums/ContactRelationType'

export const getCoveredAndCharge = (payment) => {
  const chargeValue = payment?.chargeValue || 0
  return [0,chargeValue]
}

const INSURANCE_PREFIX = 'patientInfo.insurances'
export const INSURANCE_REQUIRED_KEYS = ['insuranceCarrier', 'memberNumber', 'groupNumber']
export const ALL_INSURANCE_REQUIRED_KEYS = INSURANCE_REQUIRED_KEYS.concat("insurancedName")
export const MEDICARE_REQUIRED_KEYS = ['code']
export const OTHER_REQUIRED_KEYS = ['info']
const KEYS_FOR_TYPE = {
  [MEDICARE]:MEDICARE_REQUIRED_KEYS,
  [OTHER]: OTHER_REQUIRED_KEYS,
  [PRIVATE]: INSURANCE_REQUIRED_KEYS
}
const KEYS_FOR_TYPE_ALL = {
  ...KEYS_FOR_TYPE,
  [PRIVATE]: ALL_INSURANCE_REQUIRED_KEYS
}
export const checkInsurance = ({id,type,...rest}, i, forceValidation) => (forceValidation || Object.values(rest).find(_.identity))
    ? generalCheckInsurance(rest,type,i)
    : []
const generalCheckInsurance = (insurance, type, i) => {
  return KEYS_FOR_TYPE[type]
    .map(k => [INSURANCE_PREFIX + '[' + i + '].' + k, required(insurance[k])]).filter(([k, v]) => v)
}

export const removeExtraInsuranceFields = ({id,type,...rest}) =>
  ({id,type,..._.pick(KEYS_FOR_TYPE_ALL[type],rest)})

export const buildIntakeFormsSteps = ({intakeFormsProviderInfo, language, isAdmin, ehrHl7Enabled, isNurse = false}) => {
  const isLegal = language.indexOf("LEGAL") >= 0

  let steps = isLegal ? [{
      number: 0,
      label: "Client identification",
      pageTitle: "Client identification",
      pageSubTitle: "Enter client information",
      formPath: "patientInfo"
    },
    {
      number: 1,
      label: "Contact information",
      pageTitle: "Contact information",
      pageSubTitle: "Primary contact information for the client or client representative.",
      formPath: "contactDetails"
    },
    {
      number: 4,
      label: "Case Details",
      pageTitle: "Case Details",
      formPath: "caseInfo"
    },
    {
      number: 6,
      label: "Case settings",
      pageTitle: "Case settings",
      pageSubTitle: "Manage the divisions that can access this case",
      formPath: "categories"
    }
  ] : [
    {
      number: 0,
      label: "Patient identification",
      pageTitle: "Patient identification",
      pageSubTitle: "Enter patient information",
      formPath: "patientInfo"
    },
    {
      number: 1,
      label: "Contact party",
      pageTitle: "Contact party",
      pageSubTitle: "This is the party responsible for this case. They may be contacted about patient information, medical records, payment, and the case report as applicable.",
      formPath: "contactDetails"
    },
    {
      number: 2,
      label: "Physician",
      pageTitle: "Physician (Local and/or Referring)",
      pageSubTitle: "Can be requested to take action on this case and may receive a copy of any resulting results.",
      formPath: "referringPhysicians"
    },
    {
      number: 3,
      label: "Insurance information",
      pageTitle: "Insurance information",
      pageSubTitle: "In most cases, this service will not be covered by insurance. Only complete this section if you have been advised to by your care coordinator. This information will only be used should there be any covered procedure.",
      formPath: "patientInfo.insurances"
    },
    {
      number: 4,
      label: "Primary concern",
      pageTitle: "Primary concern",
      formPath: "caseInfo"
    },
    {
      number: 5,
      label: "Case to EHR reconciliation",
      pageTitle: "Case to EHR reconciliation",
      pageSubTitle: "Insert the information below to export this case to your EHR system",  
    },
    {
      number: 6,
      label: "Case settings",
      pageTitle: "Case settings",
      pageSubTitle: "Manage the divisions that can access this case",
      formPath: "categories"
    }
  ]
  
  // Can not remove patient identification - because it is mandatory step
  // if(!intakeFormsProviderInfo?.patientIdentification) {
  //   steps = steps.filter(step => step.number !== 0)
  // }

  // Can not remove contact party - because it is mandatory step
  // if(!intakeFormsProviderInfo?.contactParty) {
  //   steps = steps.filter(step => step.number !== 1)
  // }

  if(!intakeFormsProviderInfo?.physician || isLegal) {
    steps = steps.filter(step => step.number !== 2)
  }

  if(!intakeFormsProviderInfo?.insuranceInformation || isLegal) {
    steps = steps.filter(step => step.number !== 3)
  }

  // Can not remove primary concern - because it is mandatory step
  // if(!intakeFormsProviderInfo?.primaryConcern) {
  //   steps = steps.filter(step => step.number !== 4)
  // }

  if(!ehrHl7Enabled || !isAdmin) {
    steps = steps.filter(step => step.number !== 5)
  }

  if(!isAdmin && !isNurse) {
    steps = steps.filter(step => step.number !== 6)
  }

  return steps
}