import React from 'react'

export const PATIENT = 'PATIENT'
export const CAREGIVER = 'CAREGIVER'
export const REF_PHYSICIAN = 'REF_PHYSICIAN'
export const LOCAL_PHYSICIAN = 'LOCAL_PHYSICIAN'
export const OTHER = 'OTHER'
// Need to add a "Relation to patient" field here, with the following options: 
// Legal Guardian, Executor, Parent of Minor, or "Other" with the option to fill in relation
export const LEGALGUARDIAN = 'LEGAL_GUARDIAN'
export const EXECUTOR = 'EXECUTOR'
export const PARENT = 'PARENT_OF_MINOR'

export const CONTACT_RELATION_LEGAL_LABELS = {[PATIENT]:"Client",[LEGALGUARDIAN]:"Legal Guardian",[EXECUTOR]:"Executor",[PARENT]:"Parent of Minor",[OTHER]:"Other"}
export const CONTACT_RELATION_LEGAL_OPTIONS = Object.keys(CONTACT_RELATION_LEGAL_LABELS)

export const CONTACT_RELATION_MEDICAL_LABELS = {[PATIENT]:"Patient",[CAREGIVER]:"Caregiver",[REF_PHYSICIAN]:"Referring physician", [LOCAL_PHYSICIAN]:"Local physician",[PARENT]:"Parent",[LEGALGUARDIAN]:"Legal Guardian",[OTHER]:"Other"}
export const CONTACT_RELATION_MEDICAL_OPTIONS = Object.keys(CONTACT_RELATION_MEDICAL_LABELS)