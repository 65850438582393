import { Combobox, ComboboxInput, Errors, FormValue, RadioboxGroup, SimpleRadiobox, TextInput } from '@startlibs/form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, SplitColumnsContainer } from '@startlibs/components';
import { getColor, smoothScroll } from '@startlibs/utils';
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components';
import { AFTERNOON, EVENING, MORNING } from '../../enums/ContactTime'
import { CAREGIVER, CONTACT_RELATION_LABELS, CONTACT_RELATION_LEGAL_LABELS, CONTACT_RELATION_LEGAL_OPTIONS, CONTACT_RELATION_MEDICAL_LABELS, CONTACT_RELATION_MEDICAL_OPTIONS, CONTACT_RELATION_OPTIONS, EXECUTOR, LEGALGUARDIAN, LOCAL_PHYSICIAN, OTHER, PARENT, PATIENT, REF_PHYSICIAN } from '../../enums/ContactRelationType'
import { Card, SectionHeading } from '../../components/PageLayout';
import { ConfirmEmailInput } from '../../components/ConfirmEmailInput'
import { EMAIL, PHONE, TEXT } from '../../enums/ContactCommunicationType'
import { IfLocale } from '../../hocs/IfLocale'
import { getFormattedStates } from '../PatientAndContactDetailsNew';
import { applyPhoneMask } from '../forms/utils';
import StyledTelInput from '../../components/StyledTelInput';
import { IntakeFormCard } from './PatientDetailsNew';

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

export const ComboboxContainer = styled.div`
  ${props => props.readOnly && `
    ${ComboboxInput} {
      background: rgba(0,0,0,0.075) !important;
      opacity: 0.75 !important;
      pointer-events: none !important;
      ${Icon} {
        opacity: 0.4;
      }
    }
  `}
`

const VIEWPORT_MIN_WIDTH = 500

export const ContactDetailsNew = ({ title, subTitle, readOnly, scrollTo, form, countryList, showSelects, isPatientCreating=false, 
  caseRequest, 
  contactPhoneNumberValid, setContactPhoneNumberValid,
  contactFaxNumberValid, setContactFaxNumberValid,
  NavigationButtons }) => {

  const headerRef = useRef()
  const intl = useIntl()

  useEffect(() => {
    if (scrollTo) {
      smoothScroll({ target: headerRef.current, offset: -90 })
    }
  }, [])

  return <>
    <IntakeFormCard>
      <div>
      {title && <h3>{title}</h3>}
      {subTitle && <p>{subTitle}</p>}
      <IfLocale not contains="LEGAL">
        <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
          disabled={readOnly}
          className="combobox fs-exclude"
          label={intl.formatMessage({ defaultMessage: "Select relationship to the patient", description: "ContactDetails contactRelationType label" })}
          path='contactDetails.contactRelationType'
          options={CONTACT_RELATION_MEDICAL_OPTIONS}
          getLabel={v => CONTACT_RELATION_MEDICAL_LABELS[v]}
          mandatory
        /></ComboboxContainer>
      </IfLocale>

      <IfLocale contains="LEGAL">
        <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
          <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
            disabled={readOnly}
            className="combobox fs-exclude"
            label={intl.formatMessage({ defaultMessage: "Select relationship to the patient", description: "ContactDetails contactRelationType label" })}
            path='contactDetails.contactRelationType'
            options={CONTACT_RELATION_LEGAL_OPTIONS}
            getLabel={v => CONTACT_RELATION_LEGAL_LABELS[v]}
            mandatory
          /></ComboboxContainer>
        </SplitColumnsContainer>
      </IfLocale>

      <FormValue path="contactDetails.contactRelationType">{relationtype =>
        relationtype && relationtype !== PATIENT && <>
          <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            <TextInput
              disabled={readOnly}
              type="text"
              path="contactDetails.fullName.first"
              label="Your first name"
              placeholder="Ex: John"
              maxLength={255}
              mandatory
            />
            <TextInput
              disabled={readOnly}
              type="text"
              path="contactDetails.fullName.last"
              label="Your last name"
              placeholder="Ex: Doe"
              maxLength={255}
              mandatory
            />
            {(relationtype === REF_PHYSICIAN ||relationtype === LOCAL_PHYSICIAN) && <TextInput
              disabled={readOnly}
              type="text"
              path="contactDetails.npi"
              label="NPI"
              placeholder="Ex: 1234567890"
              maxLength={255}
            // mandatory
            />}
          </SplitColumnsContainer>
          {relationtype === OTHER &&
            <TextInput
              disabled={readOnly}
              type="text"
              path="contactDetails.otherRelationType"
              label={intl.formatMessage({ defaultMessage: "Specify your relationship to the patient", description: "ContactDetails otherRelationType label" })}
              // label="Specify your relation with the patient"
              // placeholder="Ex: Father"
              maxLength={255}
              mandatory
            />
          }
        </>}</FormValue>

      {/* IF IS REF_PHYSICIAN */}
      <FormValue path="contactDetails.contactRelationType">{relationtype =>
        relationtype && (relationtype === REF_PHYSICIAN || relationtype === LOCAL_PHYSICIAN) && <>
          <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            {showSelects ? <>
              <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
                disabled={readOnly}
                className="combobox fs-exclude"
                placeholder="Please select country"
                label={"Country"}
                path='contactDetails.address.country'
                options={countryList}
                onChange={(val) => {
                  var states = getFormattedStates(val)
                  if(states.length > 0){
                    if(states.findIndex(x => x === form.getValue('contactDetails.address.state')) == -1){
                      form.setValue('contactDetails.address.state', null)
                    }
                  }
                }}
              /></ComboboxContainer>
              <FormValue path="contactDetails.address.country">{country => 
                <ComboboxContainer readOnly={readOnly} className='fs-exclude'>
                  <Combobox
                    disabled={readOnly}
                    className="combobox fs-exclude"
                    placeholder="Please select state"
                    label={"State/Province"}
                    path='contactDetails.address.state'
                    options={getFormattedStates(country)}
                    // onChange={(val) => setRegion(val)}
                  />
                </ComboboxContainer>
              }</FormValue>
            </> : <>
              <TextInput
                disabled={readOnly}
                type="text"
                path="contactDetails.address.country"
                label="Country"
                placeholder="Ex: USA"
                maxLength={255}
              // mandatory
              />
              <TextInput
                disabled={readOnly}
                type="text"
                path="contactDetails.address.state"
                label="State"
                placeholder="Ex: New York"
                maxLength={255}
              // mandatory
              />
            </>
            }
          </SplitColumnsContainer>

          <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
            <TextInput
              disabled={readOnly}
              type="text"
              path="contactDetails.address.city"
              label="City"
              placeholder="Ex: New York"
              maxLength={255}
            // mandatory
            />
            <TextInput
              disabled={readOnly}
              type="text"
              path="contactDetails.address.zipCode"
              label="Postal code"
              placeholder="Ex: 12345"
              maxLength={255}
            // mandatory
            />
          </SplitColumnsContainer>
          <TextInput
            disabled={readOnly}
            type="text"
            path="contactDetails.address.address"
            label="Street address"
            placeholder="Ex: 001 Street Name"
            maxLength={255}
          // mandatory
          />

        </>}</FormValue>
      {/* END IF IS REF_PHYSICIAN */}

      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
        <TextInput
          disabled={readOnly}
          type="text"
          path="contactDetails.email"
          label="Email address"
          placeholder="Ex: your@email.com"
          maxLength={255}
          mandatory
        />

        <ConfirmEmailInput
          disabled={readOnly}
          type="text"
          confirmingPath="contactDetails.email"
          path="contactDetails.confirmEmail"
          label="Confirm email"
          placeholder="Ex: your@email.com"
          maxLength={255}
          mandatory
          canLock={false}
        />
      </SplitColumnsContainer>
      <FormValue path="contactDetails.contactRelationType">{relationtype =>
        relationtype && (relationtype === REF_PHYSICIAN || relationtype === LOCAL_PHYSICIAN) && <>
          <FormValue path="patientInfo.address.country">{country =>
            <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
              <TextInput
                disabled={readOnly}
                type="text"
                path="contactDetails.institution"
                label="Institution"
                placeholder="Ex: Purview"
                maxLength={255}
              />
              <StyledTelInput
                path="contactDetails.faxNumber"
                hasErrors={form.hasErrors()}
                label="Fax number"
                placeholder="(201) 555-0123"
                disabled={readOnly}
                initOptions={{
                  initialCountry: "us",
                  separateDialCode: false,
                  autoPlaceholder: "aggressive",
                  formatOnDisplay: true,
                  placeholderNumberType: "FIXED_LINE_OR_MOBILE",
                  nationalMode: false,
                  countryOrder: ["us"],
                  validationNumberType: "FIXED_LINE_OR_MOBILE"
                }}
                tabIndex={1}
                isValid={ contactFaxNumberValid}
                setIsValid={setContactFaxNumberValid}
              />
            </SplitColumnsContainer>
          }</FormValue>  
        </>}
      </FormValue>
      
      <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
          <StyledTelInput
            mandatory 
            path="contactDetails.phoneNumber"
            // hasErrors={form.hasErrors()}
            hasErrors={form?.hasErrors('contactDetails.phoneNumber')}
            // current?.hasErrors(step.formPath
            label="Phone number"
            placeholder="(201) 555-0123"
            disabled={readOnly}
            initOptions={{
              initialCountry: "us",
              separateDialCode: false,
              autoPlaceholder: "aggressive",
              formatOnDisplay: true,
              placeholderNumberType: "FIXED_LINE_OR_MOBILE",
              nationalMode: false,
              countryOrder: ["us"],
              validationNumberType: "FIXED_LINE_OR_MOBILE"
            }}
            tabIndex={1}
            isValid={contactPhoneNumberValid}
            setIsValid={setContactPhoneNumberValid}
          />
          <RadioboxGroup disabled={readOnly} mandatory label="Preferred mode of communication" horizontal path="contactDetails.prefCommunicationMode" framedBoxes wide>
            <SimpleRadiobox fieldValue={PHONE} label="Phone" className='fs-exclude' />
            <SimpleRadiobox fieldValue={EMAIL} label="Email" className='fs-exclude' />
            {/* {(!isPatientCreating && (mode === TEXT || !mode)) && <SimpleRadiobox fieldValue={TEXT} label="Text" className='fs-exclude' />} */}
            {(!isPatientCreating && (caseRequest?.contactDetails?.prefCommunicationMode === TEXT)) && <SimpleRadiobox fieldValue={TEXT} label="Text" className='fs-exclude' />}
          </RadioboxGroup>
      </SplitColumnsContainer> 
      
      <RadioboxGroup disabled={readOnly} label="Preferred contact time" horizontal path="contactDetails.prefContactTime" framedBoxes wide>
        <SimpleRadiobox fieldValue={MORNING} label="Morning" className='fs-exclude' />
        <SimpleRadiobox fieldValue={AFTERNOON} label="Afternoon" className='fs-exclude' />
        <SimpleRadiobox fieldValue={EVENING} label="Evening" className='fs-exclude' />
      </RadioboxGroup>
      </div>
      <Errors path="contactDetails" />
      <NavigationButtons />
    </IntakeFormCard>
  </>
}
