import {getColor} from '@startlibs/utils';
import React from 'react'
import styled from 'styled-components';
import {Card, SectionHeading} from '../../components/PageLayout';
import {MEDICARE} from '../../enums/InsuranceType'
import {QuestionList} from '../../components/QuestionList'
import { Insurance } from '../forms/Insurance';
import { Errors } from '@startlibs/form';
import { IntakeFormCard } from './PatientDetailsNew';


export const OptionalHeading = styled.span`
  color: ${getColor('gray180')};
  font-weight: 400;
`

export const InsuranceDetailsNew = ({title, subTitle, readOnly, form, NavigationButtons}) => {
  return <>
    {/* <SectionHeading>
      <h3>Insurance information <OptionalHeading>(Optional)</OptionalHeading></h3>
      // <p>Please include the patient’s insurance details for reference by the institution.</p>
      <p>In most cases, this service will not be covered by insurance. Only complete this section if you have been advised to by your care coordinator.
        This information will only be used should there be any covered procedure.
      </p>
    </SectionHeading> */}
    <IntakeFormCard>
      <div>
        {title && <h3>{title}</h3>}
        {subTitle && <p>{subTitle}</p>}
        <QuestionList
          disabled={readOnly}
          hardLimit={3}
          min={0}
          getEmpty={() => ({id: Date.now(), type: MEDICARE})}
          path="patientInfo.insurances"
          addLabel="Add insurance"
        >{(insurance, i, arr) => <Insurance
          form={form}
          i={i}
          path={["patientInfo","insurances"]}
          disabled={readOnly}
          canRemove
        />
        }</QuestionList>
      </div>
      <Errors path={'patientInfo.insurances'}/>
      <NavigationButtons />
    </IntakeFormCard>
    {/* <p>
      Note: While some facilities may use this information if there is a
      reimbursable service provided, certain facilities will only collect
      an all-inclusive fee directly from the patient for this service.
      Please check with your facility for details.
    </p> */}
  </>
}
