import React, { useState } from 'react'
import {Errors, WithForm} from '@startlibs/form'
import {useEnsureRef, useToggle} from '@startlibs/core'
import {useNotification} from '../components/Notifications'
import {
  booleanRequired,
  buildValidation,
  confirmEmails,
  emailValidation,
  onlyIfEqual,
  onlyIfNotEqual,
  optional,
  required
} from '../utils/validation'
import _ from 'lodash/fp'
import {callIfFunction, formatDate, parseDate} from '@startlibs/utils'
import {LOCAL_PHYSICIAN, OTHER, PATIENT, REF_PHYSICIAN} from '../enums/ContactRelationType'
import {checkInsurance, INSURANCE_REQUIRED_KEYS, removeExtraInsuranceFields} from './forms/utils'
import {AcceptTermsCheckbox, requiredTerms} from './components/AcceptTermsCheckbox'
import {IfLocale, useIsLocale} from '../hocs/IfLocale'
import { lazyProviderInfo } from '../components/WithProvider'
import { CountryRegionData } from 'react-country-region-selector'
import { PatientDetailsNew } from './newforms/PatientDetailsNew'
import { ContactDetailsNew } from './newforms/ContactDetailsNew'
import { ReferringPhysicianNew } from './newforms/ReferringPhysicianNew'
import { InsuranceDetailsNew } from './newforms/InsuranceDetailsNew'
import { PrimaryConcernNew } from './newforms/PrimaryConcernNew'
import { CaseReconciliationNew } from './newforms/CaseReconciliationNew'
import { Button, Icon } from '@startlibs/components'
import { CaseSettingsNew } from './newforms/CaseSettingsNew'
import { lazyPublicCategories, lazyUserCategories } from '../admin/experts/hooks/useEditCategories'
import styled from 'styled-components'

const StepButtonsContainer = styled.div`
  justify-content: flex-end;
  padding-top: 1.5rem;
  margin-top: auto;
  display: flex;
`

const StepButton = styled(Button)`
  box-shadow: none;
  padding: 9px 1.25rem;
  font-weight: 600;
  ${Icon} {
    font-size: 12px !important;
  }
`

const NextStepButtonIcon = styled(Icon)`
  vertical-align: -1px !important;
  margin-left: 0.5rem;
`
const PreviousStepButtonIcon = styled(Icon)`
  vertical-align: -1px !important;
  margin-right: 0.5rem;
`

export const getFormattedStates = (country) => {
  var adjustedCountry = country == 'USA' ? 'United States' : country
  const selectedCountryData = CountryRegionData.find(data => {
    return data[0] === adjustedCountry || data[1] === adjustedCountry
  })
  const stateOptions = selectedCountryData 
    ? selectedCountryData[2].split("|").map(state => state.split("~")[0])
    : []
    stateOptions.unshift('')
  return stateOptions
}

const dobTransform = _.update('dob', (d) => formatDate(d, "yyyy-MM-dd"))
const dobParse = _.update('dob', (d) => d && parseDate(d, null, "yyyy-MM-dd"))

const alreadyHasEmail = (path, caseRequest, props) => caseRequest && _.get(path, caseRequest) === _.get(path, props)


export const PatientAndContactDetailsNew = ({
                                           scrollToContact, scrollToPrimaryConcern, hideErrors, onChange,
                                           action, isPatient, isNurse, isRefPhysician, isPatientCreating, readOnly, onSuccess, onFailure, children, formRef, caseRequest, isAdmin, acceptedJurisdictions = null,
                                           steps, selectedStepNumber, setSelectedStepNumber,
                                           categories, markedAsPriority,
                                           values = {
    contactDetails: {contactRelationType: isRefPhysician ? REF_PHYSICIAN : PATIENT},
    patientInfo: {insurances:[]},
    referringPhysician: isPatientCreating ? {acceptContact: false} : {},
    caseInfo: {hasNewDiagnosisChangedTreatmentOrRecommendation: null},
    // caseInfo: {},
    categories:[],
  }
                                         }) => {


  const ensuredFormRef = useEnsureRef(formRef)
  const providerInfo = lazyProviderInfo.read()

  const [contactPhoneNumberValid, setContactPhoneNumberValid] = useState(true)
  const [contactFaxNumberValid, setContactFaxNumberValid] = useState(true)
  const [physicianPhoneNumber1Valid, setPhysicianPhoneNumber1Valid] = useState(true)
  const [physicianFaxNumber1Valid, setPhysicianFaxNumber1Valid] = useState(true)
  const [physicianPhoneNumber2Valid, setPhysicianPhoneNumber2Valid] = useState(true)
  const [physicianFaxNumber2Valid, setPhysicianFaxNumber2Valid] = useState(true)

  const NavigationButtons = () => {
    // Find the next and previous step numbers based on availability
    const stepNumbers = steps.map((step) => step.number);
    const currentIndex = stepNumbers.indexOf(selectedStepNumber);
  
    const getNextStep = () => stepNumbers[currentIndex + 1] ?? null;
    const getPreviousStep = () => stepNumbers[currentIndex - 1] ?? null;
  
    return (
      <StepButtonsContainer>
        {(steps && steps.length > 1 && selectedStepNumber >= 1) && 
          selectedStepNumber === stepNumbers[stepNumbers.length - 1] ?
          <StepButton outline rounded onClick={() => setSelectedStepNumber(getPreviousStep())}>
            <PreviousStepButtonIcon icon="arrow-left"/>Previous step
          </StepButton>
          :
          selectedStepNumber >= 1 && <StepButton outline onlyIcon icon="arrow-left" rounded onClick={() => setSelectedStepNumber(getPreviousStep())}/>
        }
        {steps && steps.length > 1 && currentIndex < stepNumbers.length - 1 && (
          <StepButton outline rounded onClick={() => setSelectedStepNumber(getNextStep())}>Next step<NextStepButtonIcon icon="arrow-right"/></StepButton>
          // <StepButton outline rounded 
          //   onClick={async () => {
          //     // await formRef.current.clearErrors()
          //     // await formRef.current.validate()
          //     // if(!formRef.current.hasErrors()){
          //       setSelectedStepNumber(getNextStep())
          //     // }
          //   }}
          // >
          //   Next step
          //   <NextStepButtonIcon icon="arrow-right"/>
          // </StepButton>
          
        )}
      </StepButtonsContainer>
    );
  };


  // Country and Regions data using react-country-region-selector
  // Documentation: https://country-regions.github.io/react-country-region-selector/
  // insert an empty option at the beginning
  const countryList = CountryRegionData.map(country => country[0])
  // Find the index of 'United States'
  const usIndex = countryList.findIndex(country => country === 'United States');
  if (usIndex !== -1) {
    // Remove 'United States' from its current position
    const usItem = countryList.splice(usIndex, 1)[0];
    // Add 'United States' to the start of the array
    countryList.unshift(usItem);
  }
  countryList.unshift('')
  
  const patientCountryAndStateEmpty = caseRequest?.patientInfo?.address?.country == '' && caseRequest?.patientInfo?.address?.state == ''
  const patientCountryAndStateNotExists= caseRequest?.patientInfo?.address == null
  const patientCountryAndStateNull= caseRequest?.patientInfo?.address?.country == null && caseRequest?.patientInfo?.address?.state == null
  const patientCountry = caseRequest?.patientInfo?.address?.country === 'USA' ? 'US' : caseRequest?.patientInfo?.address?.country
  const patientState = caseRequest?.patientInfo?.address?.state
  const patientCountryInList = patientCountry && CountryRegionData.find(country => country[0] === patientCountry || country[1] === patientCountry)
  const patientStateInList = patientState && patientCountryInList && patientCountryInList[2].split("|")
    .find(state => state.split("~")[0] === patientState || state.split("~")[1] === patientState)
  const fullCountryName = patientCountryInList && patientCountryInList[0]
  const fullStateName = patientStateInList && patientStateInList.split("~")[0]
  const showSelects = (!!fullCountryName && !!fullStateName) || (patientCountryAndStateEmpty) || (patientCountryAndStateNotExists) || (patientCountryAndStateNull)

  const contactCountryAndStateEmpty = caseRequest?.contactDetails?.address?.country == '' && caseRequest?.contactDetails?.address?.state == ''
  const contactCountryAndStateNotExists= caseRequest?.contactDetails?.address == null 
  const contactCountryAndStateNull= caseRequest?.contactDetails?.address?.country == null && caseRequest?.contactDetails?.address?.state == null
  const contactCountry = caseRequest?.contactDetails?.address?.country === 'USA' ? 'US' : caseRequest?.contactDetails?.address?.country
  const contactState = caseRequest?.contactDetails?.address?.state
  const contactCountryInList = contactCountry && CountryRegionData.find(country => country[0] === contactCountry || country[1] === contactCountry)
  const contactStateInList = contactState && contactCountryInList && contactCountryInList[2].split("|")
    .find(state => state.split("~")[0] === contactState || state.split("~")[1] === contactState)
  const fullContactCountryName = contactCountryInList && contactCountryInList[0]
  const fullContactStateName = contactStateInList && contactStateInList.split("~")[0]
  const showContactSelects = (!!fullContactCountryName && !!fullContactStateName) || (contactCountryAndStateNotExists) || (contactCountryAndStateEmpty) || (contactCountryAndStateNull)
  
  
  const phyCountryAndStateEmpty = caseRequest?.referringPhysician?.address?.country == '' && caseRequest?.referringPhysician?.address?.state == ''
  const phyCountryAndStateNotExists= caseRequest?.referringPhysician?.address == null
  const phyCountryAndStateNull= caseRequest?.referringPhysician?.address?.country == null && caseRequest?.referringPhysician?.address?.state == null
  const phyCountry = caseRequest?.referringPhysician?.address?.country === 'USA' ? 'US' : caseRequest?.referringPhysician?.address?.country
  const phyState = caseRequest?.referringPhysician?.address?.state
  const phyCountryInList = phyCountry && CountryRegionData.find(country => country[0] === phyCountry || country[1] === phyCountry)
  const phyStateInList = phyState && phyCountryInList && phyCountryInList[2].split("|")
    .find(state => state.split("~")[0] === phyState || state.split("~")[1] === phyState)
  const fullPhyCountryName = phyCountryInList && phyCountryInList[0] 
  const fullPhyStateName = phyStateInList && phyStateInList.split("~")[0]
  const showPhySelects = (!!fullPhyCountryName && !!fullPhyStateName) || (phyCountryAndStateEmpty) || (phyCountryAndStateNotExists) || (phyCountryAndStateNull)
  
  const updatedValues = _.flow(
    !!fullCountryName ? _.set('patientInfo.address.country', fullCountryName) : _.identity,
    !!fullStateName ? _.set('patientInfo.address.state', fullStateName) : _.identity,
    !!fullContactCountryName ? _.set('contactDetails.address.country', fullContactCountryName) : _.identity,
    !!fullContactStateName ? _.set('contactDetails.address.state', fullContactStateName) : _.identity,
    !!fullPhyCountryName ? _.set('referringPhysician.address.country', fullPhyCountryName) : _.identity,
    !!fullPhyStateName ? _.set('referringPhysician.address.state', fullPhyStateName) : _.identity,
    _.update('patientInfo.insurances',(insurances) => insurances?.length > 0 ? insurances : []),
    _.set('referringPhysicians', [{ role: REF_PHYSICIAN }]),
  )
  const transform = _.flow(
    (values) => values.contactDetails.contactRelationType === REF_PHYSICIAN ? _.set('referringPhysician', {}, values) : values,
    (values) => values.contactDetails.contactRelationType === LOCAL_PHYSICIAN ? _.set('localPhysician', {}, values) : values,
    _.update('patientInfo.dob', (date) => formatDate(new Date(date), "MM-dd-yyyy")),
    _.update('patientInfo.insurances', (insurances) => insurances?.filter((insurance,i) => !checkInsurance(insurance,i,true).length)),
    _.update('patientInfo.insurances', (insurances) => insurances.map(removeExtraInsuranceFields)),
    (values) => values?.patientInfo?.alternateAddress === "" ? _.unset('patientInfo.alternateAddress', values) : values,
    (values) => values.caseInfo.hasBeenTreatedOrSurgeryBefore === false 
      ?  _.update('caseInfo.treatedOrSurgeryBeforeDescription', () => '',values)
      : values,
    (values) => values.referringPhysicians?.length > 0 && values.referringPhysicians[0].role === REF_PHYSICIAN
      ? _.set('referringPhysician', values.referringPhysicians[0], values)
      : _.set('localPhysician', values.referringPhysicians[0], values),
    (values) => values.referringPhysicians?.length > 1 && values.referringPhysicians[1].role === REF_PHYSICIAN
      ? _.set('referringPhysician', values.referringPhysicians[1], values)
      : values.referringPhysicians?.length > 1 ? _.set('localPhysician', values.referringPhysicians[1], values) : values,

  )

  const isLegal = useIsLocale({contains:'LEGAL'})

  const preValidation = buildValidation({
    'categories': (categories) => categories && categories.find(_.isString) && "Please select a valid category from the list." ,
    'patientInfo.firstName': [required],
    'patientInfo.lastName': [required],
    'patientInfo.dob': [required, (d) => d === -1 && "Invalid date"],
    'patientInfo.gender': [required],
    'patientInfo.address.country': [required],
    'patientInfo.address.state': [required],
    'patientInfo.address.city': [required],
    'patientInfo.address.address': [required],
    'patientInfo.address.zipCode': [required],
    'contactDetails.contactRelationType': [required],
    'contactDetails.fullName.first': [onlyIfNotEqual('contactDetails.contactRelationType', PATIENT, required)],
    'contactDetails.fullName.last': [onlyIfNotEqual('contactDetails.contactRelationType', PATIENT, required)],
    'contactDetails.otherRelationType': [onlyIfEqual('contactDetails.contactRelationType', OTHER, required)],
    'contactDetails.email': [required, emailValidation],
    'contactDetails.confirmEmail': [(confirm, k, props) => !alreadyHasEmail('contactDetails.email', caseRequest, props) && confirmEmails('contactDetails.email')(confirm, k, props)],
    // 'contactDetails.phoneNumber': [required],
    // phone number is required and must only contain numbers and symbols but no letter
    // 'contactDetails.phoneNumber': [(v) => contactPhoneNumberValid ? undefined : "Please enter a valid phone number"],
    'contactDetails.phoneNumber': [required, (v) => contactPhoneNumberValid ? undefined : "Please enter a valid phone number"],
    'contactDetails.faxNumber': [
      onlyIfEqual('contactDetails.contactRelationType', REF_PHYSICIAN, (v) => (
        (v && v.match(/^[0-9\-\+\(\)\s]+$/) && contactFaxNumberValid) || v === "" || !v) ? undefined : "Please enter a valid fax number"),
      onlyIfEqual('contactDetails.contactRelationType', LOCAL_PHYSICIAN, (v) => (
        (v && v.match(/^[0-9\-\+\(\)\s]+$/) && contactFaxNumberValid) || v === "" || !v) ? undefined : "Please enter a valid fax number")
    ],
    // 'contactDetails.phoneNumber': [required, (v) => v && v.match(/^[0-9\-\+\(\)\s]+$/) ? undefined : "Please enter a valid phone number"],
    'contactDetails.prefCommunicationMode': [required],
    // 'contactDetails.prefContactTime': [required],
    // 'referringPhysician.name': [(v,k,props) => props.contactDetails.relationType !== REF_PHYSICIAN && required(v)],
    // 'referringPhysician.email': [onlyIfNotEqual('contactDetails.contactRelationType', REF_PHYSICIAN, optional(emailValidation))],
    // 'referringPhysician.confirmEmail': [onlyIfNotEqual('contactDetails.contactRelationType', REF_PHYSICIAN, (v, k, props) => !alreadyHasEmail('referringPhysician.email', caseRequest, props) && confirmEmails('referringPhysician.email')(v, k, props))],
    // 'localPhysician.email': [onlyIfNotEqual('contactDetails.contactRelationType', LOCAL_PHYSICIAN, optional(emailValidation))],
    // 'localPhysician.confirmEmail': [onlyIfNotEqual('contactDetails.contactRelationType', LOCAL_PHYSICIAN, (v, k, props) => !alreadyHasEmail('localPhysician.email', caseRequest, props) && confirmEmails('localPhysician.email')(v, k, props))],

    'referringPhysicians[0].email': [optional(emailValidation)],
    'referringPhysicians[0].confirmEmail': [(v, k, props) => !alreadyHasEmail('referringPhysicians[0].email', caseRequest, props) && confirmEmails('referringPhysicians[0].email')(v, k, props)],
    'referringPhysicians[1].email': [optional(emailValidation)],
    'referringPhysicians[1].confirmEmail': [(v, k, props) => !alreadyHasEmail('referringPhysicians[1].email', caseRequest, props) && confirmEmails('referringPhysicians[1].email')(v, k, props)],
    'referringPhysicians[0].phoneNumber': [(v) => !v || v === "" || physicianPhoneNumber1Valid ? undefined : "Please enter a valid phone number"],
    'referringPhysicians[0].faxNumber': [(v) => !v || v === "" || physicianFaxNumber1Valid ? undefined : "Please enter a valid fax number"],
    'referringPhysicians[1].phoneNumber': [(v) => !v || v === "" || physicianPhoneNumber2Valid ? undefined : "Please enter a valid phone number"],
    'referringPhysicians[1].faxNumber': [(v) => !v || v === "" || physicianFaxNumber2Valid ? undefined : "Please enter a valid fax number"],

    'caseInfo.clinicalSummary': [required],
    'caseInfo.intakeDiagnosis': isLegal ? [] : values?.caseInfo?.intakeDiagnosis !== null ? [required] : [],
    'caseInfo.hasBeenTreatedOrSurgeryBefore': isLegal ? [] : [booleanRequired],
    'acceptedTerms': isPatient && requiredTerms(caseRequest),

    "referringPhysicians[0].role" : [required],
    "referringPhysicians[0].role" : (v, k, props) => v && props.referringPhysicians[0].role && props.referringPhysicians.length > 1 
      && props.referringPhysicians[1].role === v && "You can have only one physician of each type",
    "referringPhysicians[1].role" : (v, k, props) => v && props.referringPhysicians[1].role && props.referringPhysicians.length > 1 
      && (props.contactDetails.contactRelationType === REF_PHYSICIAN || props.contactDetails.contactRelationType === LOCAL_PHYSICIAN)  && "You can have only one physician with a different type from your contact",
  }, ({patientInfo: {insurances}}) => {
    if (insurances && insurances.length) {
      const errorsListList = insurances.map((insurance,i) => checkInsurance(insurance,i))
      const hasOneSuccess = errorsListList.find((list, i) => i === 0 && !list.length)
      return {
        ..._.fromPairs(_.flatten(errorsListList.filter(list => hasOneSuccess ? list.length < INSURANCE_REQUIRED_KEYS.length : true)))
      }
    }
  })

  return <WithForm
    alwaysSave
    onChange={onChange}
    ref={ensuredFormRef}
    transform={transform}
    action={action}
    onSuccess={onSuccess}
    onFailure={onFailure}
    preValidation={preValidation}
    // values={fixMedicareInsideInsurances(values)}
    values={updatedValues(values)}
  >{form => <>
    {/* <PatientDetails isAdmin={isAdmin} readOnly={readOnly} country={country} region={region} setCountry={setCountry} setRegion={setRegion} form={form} countryList={countryList} showSelects={showSelects}/> */}
    {selectedStepNumber === 0 && <PatientDetailsNew title={steps.find(s => s.number === 0)?.pageTitle} isAdmin={isAdmin} readOnly={readOnly} form={form} countryList={countryList} showSelects={showSelects} 
      acceptedJurisdictions={isAdmin ? acceptedJurisdictions : null}
      isPatientCreating={isPatientCreating}
      NavigationButtons={NavigationButtons}
    />}
    {selectedStepNumber === 1 && <ContactDetailsNew 
      title={steps.find(s => s.number === 1)?.pageTitle} 
      subTitle={steps.find(s => s.number === 1)?.pageSubTitle} 
      scrollTo={scrollToContact} 
      readOnly={readOnly} 
      form={form} 
      countryList={countryList} 
      showSelects={showContactSelects} 
      isPatientCreating={isPatientCreating} 
      caseRequest={caseRequest} 
      contactPhoneNumberValid={contactPhoneNumberValid} 
      setContactPhoneNumberValid={setContactPhoneNumberValid}
      contactFaxNumberValid={contactFaxNumberValid}
      setContactFaxNumberValid={setContactFaxNumberValid}
      NavigationButtons={NavigationButtons}/>}
    <IfLocale not contains='LEGAL'>
      {selectedStepNumber === 2 && <ReferringPhysicianNew 
        title={steps.find(s => s.number === 2)?.pageTitle} 
        subTitle={steps.find(s => s.number === 2)?.pageSubTitle} 
        readOnly={readOnly} 
        isAdmin={isAdmin} 
        form={form} 
        countryList={countryList} 
        showSelects={showPhySelects}
        physicianPhoneNumber1Valid={physicianPhoneNumber1Valid}
        setPhysicianPhoneNumber1Valid={setPhysicianPhoneNumber1Valid}
        physicianFaxNumber1Valid={physicianFaxNumber1Valid}
        setPhysicianFaxNumber1Valid={setPhysicianFaxNumber1Valid}
        physicianPhoneNumber2Valid={physicianPhoneNumber2Valid}
        setPhysicianPhoneNumber2Valid={setPhysicianPhoneNumber2Valid}
        physicianFaxNumber2Valid={physicianFaxNumber2Valid}
        setPhysicianFaxNumber2Valid={setPhysicianFaxNumber2Valid} 
        NavigationButtons={NavigationButtons}/>}
      {selectedStepNumber === 3 && <InsuranceDetailsNew title={steps.find(s => s.number === 3)?.pageTitle} subTitle={steps.find(s => s.number === 3)?.pageSubTitle} readOnly={readOnly} form={form} NavigationButtons={NavigationButtons}/>}
    </IfLocale>
    {selectedStepNumber === 4 && <PrimaryConcernNew title={steps.find(s => s.number === 4)?.pageTitle} subTitle={steps.find(s => s.number === 4)?.pageSubTitle} readOnly={readOnly} scrollTo={scrollToPrimaryConcern} NavigationButtons={NavigationButtons}/>}
    <IfLocale not contains='LEGAL'>
      {providerInfo?.ehrHl7Enabled === true && isAdmin && caseRequest === undefined && 
        selectedStepNumber === 5 && <CaseReconciliationNew title={steps.find(s => s.number === 5)?.pageTitle} subTitle={steps.find(s => s.number === 5)?.pageSubTitle} NavigationButtons={NavigationButtons}/>
      }
    </IfLocale>
    {selectedStepNumber === 6 && <CaseSettingsNew 
      lazyCategories={isNurse ? lazyPublicCategories : lazyUserCategories}
      title={steps.find(s => s.number === 6)?.pageTitle} 
      subTitle={steps.find(s => s.number === 6)?.pageSubTitle}
      NavigationButtons={NavigationButtons}
      form={form}
      caseRequest={{categories:categories?.isOpen,markedAsPriority:markedAsPriority.isOpen}}
                          setCaseRequest={(update) => form.setValues(update)}
      
    />}
    {isPatient && !readOnly && <AcceptTermsCheckbox caseRequest={caseRequest}/>}

    {!hideErrors && <Errors/>}
    {callIfFunction(children, form)}
  </>}</WithForm>
}
