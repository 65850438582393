import { Button, Dialog } from '@startlibs/components';
import {Fill, useEnsureRef, useRefState, useToggle} from '@startlibs/core';
import { getColor, parseDate, postFetcher } from '@startlibs/utils';
import { lighten } from 'polished';
import {useNavigate} from 'react-router'
import { useIntl } from 'react-intl';
import React, {useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'
import load from 'little-loader'
import styled, {createGlobalStyle} from 'styled-components';
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout'
import {CategoriesBox, lazyPublicCategories} from '../admin/experts/hooks/useEditCategories'
import {ConfirmDialog, useConfirmDialog} from '../hooks/useConfirmDialog'
import {Header} from '../components/Header'
import {LogoutButton} from '../components/Navbar';
import {OptionalHeading} from '../request/forms/InsuranceDetails'
import {PENDING_REQUEST} from '../enums/CaseState'
import {PurviewFooter} from '../components/PurviewFooter'
import {ServiceTermsContent} from './PatientSignTerms'
import {useUrgentNoticeDismissed} from '../pages/LandingPage'
import {createCaseTransform, createLegalCaseTransform} from '../request/utils/createCaseUtils'
import {getTimezoneOffset} from '../utils/utils'
import {responseFailure} from '../utils/validation'
import {setJwt} from '../hooks/useJwt'
import {willUseSuspense} from '../hooks/useSuspense'
import { convertToCareQualityGender } from '../enums/GenderCarequality';
import { jwtPostFetcher } from '../utils/authFetch';
import { CountryRegionData } from 'react-country-region-selector';
import { buildIntakeFormsSteps } from '../request/forms/utils';
import { PatientAndContactDetailsNew } from '../request/PatientAndContactDetailsNew';
import { HasErrorsLabel, PanelStepsAndForm, Step, StepsWrapper } from '../admin/AdminCreateRequestNew';
import { IfLocale, useIsLocale } from '../hocs/IfLocale';
import { GENDER_LABELS } from '../enums/Gender';
import { PATIENT } from '../enums/UserRoles';
import { getRelationLabel } from '../enums/ContactRelationKind';
import { setNotification } from '../components/Notifications';

const formatDate = (values) => _.update('patientInfo.dob', (date) => parseDate(date, null, 'MM-dd-yyyy'), values)

const captchaPromise = new Promise((res, rej) => {
  window.onLoadCaptchResolve = res
})

const useLoadCaptcha = willUseSuspense((captchaEnabled) => {
  if (!captchaEnabled) {
    return Promise.resolve()
  }
  load("https://www.google.com/recaptcha/api.js?onload=onLoadCaptchResolve&render=explicit")
  return captchaPromise
})

const HideRecaptha = createGlobalStyle`
.grecaptcha-badge { visibility: hidden; }
`

export const RecaptchaBrandingText = styled.div`
  color: rgba(0,0,0,0.4);
  font-size: 12px;
  margin-right: auto;
  padding-right: 1rem;
  align-self: center;
  a {
    color: inherit;
  }
`

export const EmergencyDisclaimer = styled.div `
  margin: -1rem 0 1.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: 6px;
  background: ${props => lighten(0.48, props.theme.colors.alert)};
  border: 1px solid ${props => lighten(0.4, props.theme.colors.alert)};
  color: ${getColor('alert')};
`

const CasePreview = styled.div`
    margin-top: -1.75rem;
    h4 {
      margin-top: 2.5rem;
      border-bottom: 1px solid ${getColor('gray230')};
      padding-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
`

export const PatientCreateRequestNew = ({setCaseRequest, isNurse, isRefPhysician, providerInfo, onChange, formRef, markedAsPriority}) => {

  const navigate = useNavigate()
  const intl = useIntl()

  const ensuredFormRef = useEnsureRef(formRef)

  const captchaEnabled = providerInfo.captchaEnabled

  useLoadCaptcha(captchaEnabled)

  
  const captchaRef = useRef()
  const submitValues = useRefState()
  const isLegal = useIsLocale({contains:'LEGAL'})
  const [isPreview, setIsPreview] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [selectedStepNumber, setSelectedStepNumber] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const categories = useToggle([])

  const careQualityEnabled = providerInfo?.carequalityEnabled && !intl.locale.indexOf("LEGAL") >= 0

  const intakeFormsSteps = buildIntakeFormsSteps({
    intakeFormsProviderInfo: providerInfo?.intakeForm, 
    language: providerInfo?.locale,
    isAdmin: false,
    ehrHl7Enabled: false,
    isNurse: isNurse,
  })

  const [steps] = useState(intakeFormsSteps)
  const stepsWrapperRef = useRef(null);
  useEffect(() => {
    if (stepsWrapperRef.current && selectedStepNumber !== null) {
      const container = stepsWrapperRef.current;
      const stepIndex = steps.findIndex(step => step.number === selectedStepNumber);
      const stepElement = container.children[stepIndex];

      if (stepElement) {
        const containerWidth = container.offsetWidth;
        const stepWidth = stepElement.offsetWidth;
        const stepOffsetLeft = stepElement.offsetLeft;

        // Calculate target scroll position
        const targetScrollPosition = stepOffsetLeft - (containerWidth / 2 - stepWidth / 2);

        if (stepIndex === 0) {
          // Align the first step to the start
          container.scrollTo({ left: 0, behavior: 'smooth' });
        } else if (stepIndex === steps.length - 1) {
          // Align the last step to the end
          const lastStepVisiblePosition = container.scrollWidth - containerWidth;
          container.scrollTo({ left: lastStepVisiblePosition, behavior: 'smooth' });
        } else {
          // Center the clicked step
          container.scrollTo({ left: targetScrollPosition, behavior: 'smooth' });
        }
      }
    }
  }, [selectedStepNumber, steps]);

  const captchaCallback = (captchaToken) => postFetcher('/api/createCaseRequest', intl.locale.indexOf("LEGAL") >= 0 ? createLegalCaseTransform({
    referringPhysician: {},
    ...submitValues.get().values,
    byNurse: isNurse,
    byPhysician: isRefPhysician,
    captchaToken
  }) : 
  createCaseTransform({
    referringPhysician: {},
    ...submitValues.get().values,
    byNurse: isNurse,
    byPhysician: isRefPhysician,
    captchaToken
  }))
    .then(submitValues.get().res, submitValues.get().rej)

  useEffect(() => {
    if (captchaEnabled) {
      window.grecaptcha.ready(function () {
        window.grecaptcha.render(captchaRef.current, {
          "sitekey": "6LeBfaUZAAAAAEQNeD7e0EcudKBnZJnuZAqGAZ49",
          "size": "invisible",
          callback: captchaCallback
        });
      });
    }
  }, [])

  const discardRequest = useConfirmDialog(
    <ConfirmDialog
      title="Confirm discard request"
      navigate="/access"
      confirm={<Button alert>Discard request</Button>}
    >
      <p>You are about to discard this request and the provided information.</p>
      <p>Are you sure you want to discard? Once confirmed it can not be undone.</p>
    </ConfirmDialog>
  )

  const action = (values) => new Promise((res, rej) => {
    submitValues.set({values, res, rej})
    if (captchaEnabled) {
      window.grecaptcha.execute()
      const iframe = document.querySelector("iframe[title='recaptcha challenge']")
      if (!iframe?.parentNode?.parentNode) {
        return;
      }
      const container = iframe.parentNode.parentNode;
      const observer = new MutationObserver(mutations => {
        if (container && container.style.visibility === 'hidden') {
          if (!window.grecaptcha.getResponse()) {
            rej([values, {}])
          }
          observer.disconnect()
        }
      });
      observer.observe(container, {attributes: true, attributeFilter: ['style']});
    } else {
      captchaCallback()
    }
  })

  const onSuccess = (values, {jwt, requestId, requestCode, acceptanceInfo}) => {
    // Fullstory disabled
    // window.FS.setUserVars({
    //   displayName: requestCode,
    //   requestCode : requestCode,
    //   requestId: requestId,
    //   type: "PATIENT",
    //   customer: providerInfo.name
    // })
    // var now = new Date()
    // var eventProperties = {
    //   customer_str: providerInfo.name,
    //   dateTime_date: now,
    //   requestId_int: requestId,
    //   caseId_str: requestCode
    // }
    // window.FS.event('Patient Case Created',eventProperties)
    setCaseRequest({
      ...values,
      newlyCreated: true,
      needReleases: true,
      payments: [],
      caseFlags: {requestFlags: {}},
      requestId: requestId,
      requestCode: requestCode,
      state: PENDING_REQUEST,
      acceptanceInfo: acceptanceInfo || values.acceptanceInfo || {}
    })
    setJwt(jwt)

    if(careQualityEnabled){

      const birthDate = (() => {
          const dob = values?.patientInfo?.dob; // Assuming dob is in a proper string format like "YYYY-MM-DD"
          if (!dob) return null; // Handle null or undefined
          try {
              // Ensure the date format is compatible across all browsers
              const parsedDate = new Date(dob.replace(/-/g, '/')); // Replace hyphens with slashes for Safari compatibility
              return parsedDate.toISOString().split('T')[0];
          } catch (error) {
              console.error('Invalid Date Format:', error);
              return null;
          }
      })();

      
      const givenName = values?.patientInfo?.firstName.split(' ').filter(m => m.length > 0)
        .concat(values?.patientInfo?.middleName ? values.patientInfo.middleName.split(' ').filter(m => m.length > 0) : []);

      const getStateCode = (state, country) => {
        if(!state) return null
        if(!country) return state
        try{
          if(country === 'US' || country === 'USA' || country === 'United States' || country === 'United States of America'){
            return CountryRegionData
              .find(country => {
                return country[1] === 'US';
              })[2]
              .split("|")
              .find(st => st.split("~")[0] === state).split("~")[1];
          }else{
    
            return CountryRegionData
              .find(c => {
                return c[0] === country;
              })[2]
              .split("|")
              .find(st => st.split("~")[0] === state).split("~")[1];
          }
        }catch(e){
          return state
        }
      }

      const addressLine = [
        values?.patientInfo?.address?.address,
        values?.patientInfo?.address?.address2
      ].filter(Boolean).join(" ");

      const params = {
        zipCode: values?.patientInfo?.address?.zipCode,
        radius: 15,
        organizationName: null,
        patientDemographics: {
          familyName: values?.patientInfo?.lastName,
          givenName: givenName,
          gender: convertToCareQualityGender(values?.patientInfo?.gender),
          // birthDate: values?.patientInfo?.dob,
          // birthDate: new Date(values?.patientInfo?.dob).toISOString().split('T')[0],
          birthDate: birthDate,
          addressLine: addressLine,
          city: values?.patientInfo?.address?.city,
          state: getStateCode(values?.patientInfo?.address?.state, values?.patientInfo?.address?.country),
          postalCode: values?.patientInfo?.address?.zipCode,
          country: values?.patientInfo?.address?.country
        } 
      }
      jwtPostFetcher(jwt)(`/api/carequality/queryForDocuments?requestId=${requestId}`, params)
    }
    
    return isRefPhysician ? navigate("../records") :  navigate("../status")
    // return isRefPhysician ? navigate("records") :  navigate("status")
  }

  const onFailure = responseFailure((data, {status}) => {
    if(captchaEnabled){
      window.grecaptcha.reset()
    }
    if (status === 401) {
      return {
        '': "An error has occurred, please try again."
      }
    }
    if (status === 404) {
      return {
        '': "An error has occurred, please try again."
      }
    }
    if(status === 466) {
      console.log('466 Too many')
      return {
        '': "An error has occurred, please try again. (CODE: 466)"
      }
    }
  })

  const serviceTerms = useToggle()

  useEffect(() => {
    ensuredFormRef.current.clearErrors()
  },[serviceTerms.isOpen])

  const submitAndSaveTerms = ({signature, skipSignature, terms}) => {
    return action({...ensuredFormRef.current.getTransformedValues(),acceptedTerms: !skipSignature, ...(!skipSignature ? {serviceTermsInfo:  {signature,timezoneOffset:getTimezoneOffset()}} : {})})
      .then((response) =>
        onSuccess(
          ensuredFormRef.current.getTransformedValues(),
          response
        )
      )
  }

  const withServiceTerms = providerInfo.requiresAgreement && providerInfo.serviceTerms && !isNurse && !isRefPhysician
  const [dismissed,setDismissed] = useUrgentNoticeDismissed()

  const checkAndOpenPreview = async () => {
    await ensuredFormRef.current.clearErrors()
    await ensuredFormRef.current.validate()
    
    if(!ensuredFormRef.current.hasErrors()){
      setIsPreview(true)
    }else{
      setNotification({ type: "alert", msg: "Please check every step for incomplete or invalid fields" })
      setRefresh(refresh + 1)
      return
    }
    // formRef.current.willSubmitForm()
  }

  return <>
    <Fill name="Navbar-Action">
      <LogoutButton onClick={discardRequest}>Discard request</LogoutButton>
    </Fill>
    <PageContainer>
      <Header
        title="Expert opinion request"
      />
      {/* {providerInfo.emergencyDisclaimer && !dismissed &&
        <EmergencyDisclaimer>
          {providerInfo.emergencyDisclaimer}
        </EmergencyDisclaimer>
      } */}
      {
        serviceTerms.isOpen &&
        <ServiceTermsContent
          isPending={true}
          onFailure={onFailure}
          action={submitAndSaveTerms}
          withNonPatientSkip
          withRecaptchaVerification={captchaEnabled}
          returnButton={<Button onClick={() =>{ 
              serviceTerms.close()
              setIsSubmitting(false)
          }}>Return to request details</Button>}
        />
      }
      <div hidden={serviceTerms.isOpen}>
        <PanelStepsAndForm>
          <StepsWrapper ref={stepsWrapperRef} refresh={refresh}>
            {steps.map((step, index) => <Step
                key={index} step={step} stepNumber={index + 1} 
                onClick={() => setSelectedStepNumber(step.number)}
                selected={selectedStepNumber === step.number}
                // TODO - fix styles when there are errors
                hasErrors={step.formPath ? ensuredFormRef?.current?.hasErrors(step?.formPath) : false}
              >
                {step.label}
                {/* TODO - Icon when there are errors */}
                {step.formPath && ensuredFormRef?.current?.hasErrors(step.formPath) && 
                  <HasErrorsLabel>Review fields</HasErrorsLabel>
                }
              </Step>
            )}
          </StepsWrapper>
          <PatientAndContactDetailsNew
              isNurse={isNurse}
              isRefPhysician={isRefPhysician}
              formRef={ensuredFormRef}
              onChange={onChange}
              onFailure={onFailure}
              action={withServiceTerms ? serviceTerms.open : ((values) => action(values)
                .then((response) => onSuccess(values, response))
              )}
              // action={withServiceTerms ? submitAndSaveTerms : action}
              isAdmin={false}
              hideErrors={true}
              isPatientCreating={true}
              steps={steps}
              setSelectedStepNumber={setSelectedStepNumber}
              selectedStepNumber={selectedStepNumber}
              categories={categories}
              markedAsPriority={markedAsPriority}
          >{form => <>
            <PageFooter>
              {!withServiceTerms && captchaEnabled &&
              <RecaptchaBrandingText>
                This site is protected by reCAPTCHA and the Google <a
                href="https://policies.google.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Privacy Policy</a> and <a
                href="https://policies.google.com/terms"
                rel="noopener noreferrer"
                target="_blank"
              >Terms of Service</a> apply.
              </RecaptchaBrandingText>
              }
              {/* {
                withServiceTerms
                  ? <Button highlight type="submit">Next</Button>
                  : <Button highlight isLoading={form.isLoading} type="submit">Submit</Button>
              } */}
              {
                withServiceTerms
                  ? <Button highlight type="button" onClick={checkAndOpenPreview}>Submit</Button>
                  : <Button highlight isLoading={form.isLoading} type="button" onClick={checkAndOpenPreview}>Submit</Button>
              }

            </PageFooter>
          </>}</PatientAndContactDetailsNew>
        </PanelStepsAndForm>
        {isPreview && <Dialog
            title="Case preview"
            closeDialog={() => setIsPreview(false)}  
            footer={<>
              <Button onClick={() => setIsPreview(false)}>Return and edit</Button>
              {withServiceTerms 
                ? 
                  <Button highlight isLoading={isSubmitting} onClick={() => {
                    setIsSubmitting(true)
                    ensuredFormRef.current.willSubmitForm()
                    setTimeout(() => setIsPreview(false), 200)
                  }}>Next</Button>
                : <Button 
                    highlight 
                    isLoading={isSubmitting}
                    onClick={() => {
                      setIsSubmitting(true)
                      ensuredFormRef.current.willSubmitForm()
                      setTimeout(() => setIsPreview(false), 200)
                    }}
                >Submit</Button>
              } 
            </>}
          >
            <CasePreview>
              <div>
                {isLegal ? <h4>Client identification</h4>: <h4>Patient identification</h4>}
                <p>{ensuredFormRef.current.getValue('patientInfo.firstName')+' '}{ensuredFormRef.current.getValue('patientInfo.middleName') && ensuredFormRef.current.getValue('patientInfo.middleName')+' '}{ensuredFormRef.current.getValue('patientInfo.lastName') + ' - '}
                  { ensuredFormRef.current.getValue('patientInfo.dob') &&
                    new Date(ensuredFormRef.current.getValue('patientInfo.dob'))
                      .toISOString()
                      .split('T')[0]
                      .split('-')
                      .slice(1)
                      .concat([new Date(ensuredFormRef.current.getValue('patientInfo.dob')).toISOString().split('T')[0].split('-')[0]])
                      .join('/')
                  } - {GENDER_LABELS[ensuredFormRef.current.getValue('patientInfo.gender')]}
                </p>
                <p>{ensuredFormRef.current.getValue('patientInfo.address.address')+' - '}{ensuredFormRef.current.getValue('patientInfo.address.address2') ? ensuredFormRef.current.getValue('patientInfo.address.address2') +' - ': ''}{ensuredFormRef.current.getValue('patientInfo.address.city')+', '}{ensuredFormRef.current.getValue('patientInfo.address.state')+' '}{ensuredFormRef.current.getValue('patientInfo.address.zipCode')} </p>
                <p>{ensuredFormRef.current.getValue('patientInfo.address.country')}</p>
              </div>
              <div>
                <h4>Contact party</h4>
                <p>{ensuredFormRef.current.getValue('contactDetails.contactRelationType') === PATIENT
                  ? ensuredFormRef.current.getValue('patientInfo.firstName')+' '+(ensuredFormRef.current.getValue('patientInfo.middleName') ? ensuredFormRef.current.getValue('patientInfo.middleName')+' ' : '')+ensuredFormRef.current.getValue('patientInfo.lastName')
                  : ensuredFormRef.current.getValue('contactDetails.fullName.first')+' '+ensuredFormRef.current.getValue('contactDetails.fullName.last')
                } ({getRelationLabel(intl, ensuredFormRef.current.getValue('contactDetails.contactRelationType'), ensuredFormRef.current.getValue('contactDetails.otherRelationType'))})</p>
                <p>{ensuredFormRef.current.getValue('contactDetails.phoneNumber')} - {ensuredFormRef.current.getValue('contactDetails.email')}</p>
                <p>Preferred mode of communication: {ensuredFormRef.current.getValue('contactDetails.prefCommunicationMode').toLowerCase()} {ensuredFormRef.current.getValue('contactDetails.prefContactTime') ? <>, during the {ensuredFormRef.current.getValue('contactDetails.prefContactTime').toLowerCase()}.</> : ''}</p>
              </div>
              {!isLegal && intakeFormsSteps.findIndex(step => step.label === 'Physician') !== -1 && ensuredFormRef.current.getTransformedValues()?.referringPhysician && (ensuredFormRef.current.getTransformedValues()?.referringPhysician?.fullName?.first || ensuredFormRef.current.getTransformedValues()?.referringPhysician?.fullName?.last) && <div>
                <h4>Referring physician</h4>
                <p>
                  {ensuredFormRef.current.getTransformedValues()?.referringPhysician?.fullName?.first ? ensuredFormRef.current.getTransformedValues()?.referringPhysician?.fullName?.first + ' ' : ''}
                  {ensuredFormRef.current.getTransformedValues()?.referringPhysician?.fullName?.last ? ensuredFormRef.current.getTransformedValues()?.referringPhysician?.fullName?.last : ''}</p>
                <p>
                  {ensuredFormRef.current.getTransformedValues()?.referringPhysician?.phoneNumber 
                    ? ensuredFormRef.current.getTransformedValues()?.referringPhysician?.phoneNumber
                    : ensuredFormRef.current.getTransformedValues()?.referringPhysician?.phoneNumber
                  } 
                  {ensuredFormRef.current.getTransformedValues()?.referringPhysician?.email 
                    ? ensuredFormRef.current.getTransformedValues()?.referringPhysician?.phoneNumber 
                      ? ' - ' + ensuredFormRef.current.getTransformedValues()?.referringPhysician?.email 
                      : ensuredFormRef.current.getTransformedValues()?.referringPhysician?.email
                    : ''
                  }
                </p>
                {ensuredFormRef.current.getTransformedValues()?.referringPhysician?.institution && <p>Institution: {ensuredFormRef.current.getTransformedValues()?.referringPhysician?.institution}</p>}
              </div>}
              {!isLegal && intakeFormsSteps.findIndex(step => step.label === 'Physician') !== -1 && ensuredFormRef.current.getTransformedValues()?.localPhysician && (ensuredFormRef.current.getTransformedValues()?.localPhysician?.fullName?.first || ensuredFormRef.current.getTransformedValues()?.localPhysician?.fullName?.last) && <div>
                <h4>Local physician</h4>
                <p>
                  {ensuredFormRef.current.getTransformedValues()?.localPhysician?.fullName?.first ? ensuredFormRef.current.getTransformedValues()?.localPhysician?.fullName?.first + ' ' : ''}
                  {ensuredFormRef.current.getTransformedValues()?.localPhysician?.fullName?.last ? ensuredFormRef.current.getTransformedValues()?.localPhysician?.fullName?.last : ''}
                </p>
                <p>
                  {ensuredFormRef.current.getTransformedValues()?.localPhysician?.phoneNumber 
                    ? ensuredFormRef.current.getTransformedValues()?.localPhysician?.phoneNumber
                    : ensuredFormRef.current.getTransformedValues()?.localPhysician?.phoneNumber
                  } 
                  {ensuredFormRef.current.getTransformedValues()?.localPhysician?.email 
                    ? ensuredFormRef.current.getTransformedValues()?.localPhysician?.phoneNumber 
                      ? ' - ' + ensuredFormRef.current.getTransformedValues()?.localPhysician?.email 
                      : ensuredFormRef.current.getTransformedValues()?.localPhysician?.email
                    : ''
                  }
                </p>
                {ensuredFormRef.current.getTransformedValues()?.localPhysician?.institution && <p>Institution: {ensuredFormRef.current.getTransformedValues()?.localPhysician?.institution}</p>}
              </div>}
              {ensuredFormRef.current.getValue('caseInfo.clinicalSummary') && <div>
                <IfLocale not contains="LEGAL">
                  <h4>Primary concern</h4>
                  {ensuredFormRef.current.getValue('caseInfo.intakeDiagnosis') && <p> Primary diagnosis: {ensuredFormRef.current.getValue('caseInfo.intakeDiagnosis')}</p>}
                  {ensuredFormRef.current.getValue('caseInfo.hasBeenTreatedOrSurgeryBefore') 
                    ? <p> Patient has been treated or had surgery for this condition before: {ensuredFormRef.current.getValue('caseInfo.treatedOrSurgeryBeforeDescription')}</p>
                    : <p> Patient has not been treated or had surgery for this condition before</p>
                  }
                  {ensuredFormRef.current.getValue('caseInfo.clinicalSummary') && <p> Request description: {ensuredFormRef.current.getValue('caseInfo.clinicalSummary')}</p>}
                </IfLocale>
                <IfLocale contains="LEGAL">
                  <h4>Case details</h4>
                  {ensuredFormRef.current.getValue('caseInfo.clinicalSummary') && <p>{ensuredFormRef.current.getValue('caseInfo.clinicalSummary')}</p>}
                </IfLocale>
              </div>}
            </CasePreview>
          </Dialog>}
      </div>
      <HideRecaptha/>
      <div
        ref={captchaRef}
        className="g-recaptcha"
      />
    </PageContainer>
    <PurviewFooter/>
  </>
}

const AddCategories = ({}) => {
  const categories = lazyPublicCategories.read()
  if (!categories.length) {
    return null
  }
  return <><SectionHeading>
    <h3>Case divisions <OptionalHeading>(Optional)</OptionalHeading></h3>
    <p>You can include division(s) so that specific division users would be able to access this case.</p>
  </SectionHeading>
    <Card css="margin-bottom: 1rem;">
      <CategoriesBox lazyCategories={lazyPublicCategories} autoFocus={false}/>
    </Card>
  </>
}
