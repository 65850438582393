import {Button, Dialog, setNotification} from '@startlibs/components'
import {useNavigate} from 'react-router'
import React, {useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'
import {PageContainer, PageFooter} from '../components/PageLayout';
import {Header} from '../components/Header'
import {PageContent, PageWrapper} from './steps/RequestSidebar';
import {getJwt} from '../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {ConfirmDialog, useConfirmDialogWithProps} from '../hooks/useConfirmDialog'
import {lazyProviderInfo, lazyUserInfo} from '../components/WithProvider'
import {useToggle} from '@startlibs/core'
import {createCaseTransform, createLegalCaseTransform} from '../request/utils/createCaseUtils'
import { useIntl } from 'react-intl'
import { CountryRegionData } from 'react-country-region-selector'
import { convertToCareQualityGender } from '../enums/GenderCarequality'
import styled, { css } from 'styled-components'
import {getColor, media} from '@startlibs/utils'
import { PatientAndContactDetailsNew } from '../request/PatientAndContactDetailsNew'
import { IfLocale, useIsLocale } from '../hocs/IfLocale'
import { GENDER_LABELS } from '../enums/Gender'
import { getRelationLabel } from '../enums/ContactRelationKind'
import { PATIENT } from '../enums/ContactRelationType'
import { buildIntakeFormsSteps } from '../request/forms/utils'
import { darken } from 'polished';

export const PanelStepsAndForm = styled.div`
  display: flex;
  ${media.mobile`
    flex-direction: column;
  `}
  form{
    width: 100%;
  }
`

export const StepsWrapper = styled.div`
  flex-shrink: 0;
  position: sticky;
  top: 5rem;
  align-self: flex-start;
  // height: calc(100vh - 5rem);
  ${media.desktop`
    border: 1px solid white;
    flex-basis: 150px;
  `}
  ${media.mobile`
    background: ${getColor('gray240')}; 
    display: flex;
    scrollbar-width: none;
    overflow-y: hidden;
    position: sticky;
    top: 5rem;
    flex-shrink: 0;
    z-index: 10;
    margin: 0 -1rem;
  `}
`

export const Step = styled.div`
  min-height: 7rem;
  margin: auto;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  background: ${getColor('gray230')}; 
  flex-direction: column;
  ${props => props.hasErrors && css`
    background: #f8e0e1;
  `}
  ${media.desktop`
    :hover {
      background: ${props => darken(0.035, getColor('gray230')(props))};
    }
    :active {
      background: ${props => darken(0.06, getColor('gray230')(props))};
    }
    border-bottom: 1px solid white;
    transition: 0.5s ease;
    ${props => props.selected && css`
    box-shadow: inset 0 0 0 1px ${getColor('main')};
    background: white;
    font-weight: 600;
    pointer-events: none;
    border-radius: 3px;
    :after {
      content: "";
      position: absolute;
      border-right: 1px solid ${getColor('main')};
      border-top: 1px solid ${getColor('main')};
      background: white;
      right: -5px;
      top: ('50% - 6px');
      transform: rotate(45deg);
      width: 10px;
      height: 10px;
      animation: fadeInRight 0.25s ease-in;
    }
    @keyframes fadeInRight {
      0% {
        opacity: 0;
        pointer-events: none;
      }
      50% {
        opacity: 0;
        transform: translateX(-4px) rotate(45deg);
        pointer-events: none;
      }
      100% {
        opacity: 1;
        transform: translateX(0) rotate(45deg);
        pointer-events: none;
      }
    }
  `}
  `}
  ${media.mobile`
    font-size: 14px;
    padding: 0.75rem 1.5rem;
    min-height: 60px;
    flex-shrink: 0;
    min-width: 0;
    & ~ & {
      margin-left: 0.25rem;
    }
    ${props => props.selected && css`
      background: white;
      font-weight: 600;
    `}
  `}
`
export const HasErrorsLabel = styled.div`
    font-size: 11px;
    font-weight: 700;
    color: ${getColor('alert')};
    margin-top: 0.5rem;
    flex-shrink: 0;
    ${media.mobile`
      margin-top: 2px;
    `}
`
const CasePreview = styled.div`
    margin-top: -1.75rem;
    h4 {
      margin-top: 2.5rem;
      border-bottom: 1px solid ${getColor('gray230')};
      padding-bottom: 0.5rem;
    }
    p {
      margin-bottom: 0.5rem;
    }
`

export const AdminCreateRequestNew = () => {
  const navigate = useNavigate()
  const categories = useToggle([])
  const markedAsPriority = useToggle()
  const formRef = useRef()
  const intl = useIntl()
  const isLegal = useIsLocale({contains:'LEGAL'})

  const [isPreview, setIsPreview] = useState(false)

  const [acceptedJurisdictions, setAcceptedJurisdictions] = useState([])
  const acceptJurisdictionWarning = useToggle()
  const [selectedStepNumber, setSelectedStepNumber] = useState(0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [refresh, setRefresh] = useState(0)
  // const [ErrorProvider, errors, { setError, setErrors }] = useProvideErrors()

  useEffect(() => {
  
    jwtGetFetcher(getJwt())("/api/admin/acceptedJurisdictions").then(jurisdictions => {

      const transformedJurisdictions = jurisdictions.map(jurisdiction => {
        let newJurisdiction = jurisdiction
        
        newJurisdiction.allAvailableStates = 
          CountryRegionData
            .find(country => {
              return country[0] === jurisdiction.country;
            })[2].split("|").map(state => state.split("~")[0]);
        
        return newJurisdiction;
      });
      setAcceptedJurisdictions(transformedJurisdictions);
      
    })
    .catch(e => {
      console.log(e)
      // setNotification({ type: "alert", msg: "Failed to load accepted jurisdictions" })
    })
    
  }, [])

  

  const accept = async () => {

    await formRef.current.clearErrors()
    await formRef.current.validate()
    
    if(!formRef.current.hasErrors()){
      const country = formRef.current.getValue('patientInfo.address.country')
      const state = formRef.current.getValue('patientInfo.address.state')

      if(acceptedJurisdictions && acceptedJurisdictions.length > 0 && country && state && !acceptedJurisdictions.find(jurisdiction => jurisdiction.country === country && jurisdiction.states.includes(state))){
        acceptJurisdictionWarning.open()
        return
      }
      setIsPreview(true)
    }else{
      setNotification({ type: "alert", msg: "Please check every step for incomplete or invalid fields" })
      setRefresh(refresh + 1)
    }
    // formRef.current.willSubmitForm()
    
  }

  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()

  const careQualityEnabled = providerInfo?.carequalityEnabled && !intl.locale.indexOf("LEGAL") >= 0
  
  const saveCase = (data) => jwtPostFetcher(getJwt())('/api/admin/caseRequest', intl.locale.indexOf("LEGAL") >= 0 ? createLegalCaseTransform(data) : createCaseTransform(data))
  const onSuccess = (request,{requestCode, requestId}) => {

    if(careQualityEnabled){

        const birthDate = (() => {
          const dob = request?.patientInfo?.dob; // Assuming dob is in a proper string format like "YYYY-MM-DD"
          if (!dob) return null; // Handle null or undefined
          try {
              // Ensure the date format is compatible across all browsers
              const parsedDate = new Date(dob.replace(/-/g, '/')); // Replace hyphens with slashes for Safari compatibility
              return parsedDate.toISOString().split('T')[0];
          } catch (error) {
              console.error('Invalid Date Format:', error);
              return null;
          }
      })();

      const givenName = request?.patientInfo?.firstName.split(' ').filter(m => m.length > 0)
        .concat(request?.patientInfo?.middleName ? request.patientInfo.middleName.split(' ').filter(m => m.length > 0) : []);

      const getStateCode = (state, country) => {
        if(!state) return null
        if(!country) return state
        try{
          if(country === 'US' || country === 'USA' || country === 'United States' || country === 'United States of America'){
            return CountryRegionData
              .find(country => {
                return country[1] === 'US';
              })[2]
              .split("|")
              .find(st => st.split("~")[0] === state).split("~")[1];
          }else{
    
            return CountryRegionData
              .find(c => {
                return c[0] === country;
              })[2]
              .split("|")
              .find(st => st.split("~")[0] === state).split("~")[1];
          }
        }catch(e){
          return state
        }
      }

      const addressLine = [
        request?.patientInfo?.address?.address,
        request?.patientInfo?.address?.address2
      ].filter(Boolean).join(" ");
      
      const params = {
        zipCode: request?.patientInfo?.address?.zipCode,
        radius: 15,
        organizationName: null,
        patientDemographics: {
          familyName: request?.patientInfo?.lastName,
          givenName: givenName,
          // givenName: request?.patientInfo?.firstName.split(' ').filter(m => m.length > 0).concat(request?.patientInfo?.middleName?.split(' ').filter(m => m.length > 0).filter(m => m != null)),
          gender: convertToCareQualityGender(request?.patientInfo?.gender),
          // birthDate: new Date(request?.patientInfo?.dob).toISOString().split('T')[0],
          birthDate: birthDate,
          addressLine: addressLine,
          city: request?.patientInfo?.address?.city,
          state: getStateCode(request?.patientInfo?.address?.state, request?.patientInfo?.address?.country),
          postalCode: request?.patientInfo?.address?.zipCode,
          country: request?.patientInfo?.address?.country
        } 
      }

      jwtPostFetcher(getJwt())(`/api/carequality/queryForDocuments?requestId=${requestId}`, params)
    }

    // Fullstory disabled for now
    // var now = new Date()
    // var eventProperties = {
    //   expertName_str: userInfo.firstName + ' ' + userInfo.lastName,
    //   expertMail_str: userInfo.login,
    //   expertId_int: userInfo.id,
    //   customer_str: providerInfo.name,
    //   dateTime_date: now,
    //   requestId_int: requestId,
    //   caseId_str: requestCode
    // }
    // window.FS.event('Admin Case Created',eventProperties)
    setIsSubmitting(false)
    navigate(`/admin/request/${requestCode}/`)
    
  }

  const confirmMissingCategoriesDialog = useConfirmDialogWithProps( data =>
    <ConfirmDialog
      title="Confirm case division(s)"
      action={() => saveCase(data)}
      onSuccess={onSuccess}
      confirm={<Button alert>Confirm & lose access to case</Button>}
    >
    <p>
      If you don't include at least one division you have access to, <strong>you will not be able to access this case. </strong>
      Consider keeping at least one division you have access to.
    </p>
    <p>Are you sure you don't want to include a division you can access?</p>
  </ConfirmDialog>)

  const action = (data) => {
    const newCategories = data.categories?.map(_.get('id'))
    if (!userInfo.categories.length || userInfo.categories.find(cat => newCategories?.indexOf(cat.id)>=0)) {
      return saveCase(data)
    } else {
      confirmMissingCategoriesDialog.openWith(data)
      return Promise.reject([])
    }
  }

  const intakeFormsSteps = buildIntakeFormsSteps({
    intakeFormsProviderInfo: providerInfo?.intakeForm, 
    language: providerInfo?.locale,
    isAdmin: true, 
    ehrHl7Enabled: providerInfo?.ehrHl7Enabled,
    isNurse: false,
  })

  const [steps] = useState(intakeFormsSteps)
  const stepsWrapperRef = useRef(null);
  const handleStepClick = (step) => {
    setSelectedStepNumber(step.number);
  };
  useEffect(() => {
    if (stepsWrapperRef.current && selectedStepNumber !== null) {
      const container = stepsWrapperRef.current;
      const stepIndex = steps.findIndex(step => step.number === selectedStepNumber);
      const stepElement = container.children[stepIndex];

      if (stepElement) {
        const containerWidth = container.offsetWidth;
        const stepWidth = stepElement.offsetWidth;
        const stepOffsetLeft = stepElement.offsetLeft;

        // Calculate target scroll position
        const targetScrollPosition = stepOffsetLeft - (containerWidth / 2 - stepWidth / 2);

        if (stepIndex === 0) {
          // Align the first step to the start
          container.scrollTo({ left: 0, behavior: 'smooth' });
        } else if (stepIndex === steps.length - 1) {
          // Align the last step to the end
          const lastStepVisiblePosition = container.scrollWidth - containerWidth;
          container.scrollTo({ left: lastStepVisiblePosition, behavior: 'smooth' });
        } else {
          // Center the clicked step
          container.scrollTo({ left: targetScrollPosition, behavior: 'smooth' });
        }
      }
    }
  }, [selectedStepNumber, steps]);

  return <PageWrapper responsiveWorkaround>
    <PageContent>
      <PageContainer>
        <Header title="Create case"></Header>
        <PanelStepsAndForm>
          <StepsWrapper ref={stepsWrapperRef} refresh={refresh}>
            {steps.map((step, index) => <Step
                key={index} step={step} stepNumber={index + 1} 
                // onClick={() => setSelectedStepNumber(step.number)}
                onClick={() => handleStepClick(step, index)}
                selected={selectedStepNumber === step.number}
                hasErrors={step?.formPath ? formRef?.current?.hasErrors(step?.formPath) : false}
              >
                {step.label}
                {step.formPath && formRef?.current?.hasErrors(step.formPath) && 
                  <HasErrorsLabel>Review fields</HasErrorsLabel>
                }
              </Step>
            )}
          </StepsWrapper>
          <PatientAndContactDetailsNew
            action={action}
            formRef={formRef}
            onSuccess={onSuccess}
            onFailure={(e) => {
              console.log(e)
              setIsSubmitting(false)
            }}
            hideErrors
            isAdmin={true}
            acceptedJurisdictions={acceptedJurisdictions}
            onChange={(prev, next) => {
              if (prev.markAsPriority !== next.markAsPriority) {
                markedAsPriority.openWith(next.markAsPriority)
              }
              if (prev.categories !== next.categories) {
                categories.openWith(next.categories)
              }
            }}
            steps={steps}
            setSelectedStepNumber={setSelectedStepNumber}
            selectedStepNumber={selectedStepNumber}
            categories={categories}
            markedAsPriority={markedAsPriority}
          ></PatientAndContactDetailsNew>
        </PanelStepsAndForm>
        {isPreview && <Dialog
            title="Case preview"
            closeDialog={() => setIsPreview(false)}  
            footer={<>
              <Button onClick={() => setIsPreview(false)}>Return and edit</Button>
              <Button 
                highlight 
                isLoading={isSubmitting}
                onClick={() => {
                  setIsSubmitting(true)
                  formRef.current.willSubmitForm()
                  setTimeout(() => setIsPreview(false), 200)
                }}
              >Create case</Button>
            </>}
          >
            <CasePreview>
              <div>
                {isLegal ? <h4>Client identification</h4> : <h4>Patient identification</h4>}
                <p>{formRef.current.getValue('patientInfo.firstName')+' '}{formRef.current.getValue('patientInfo.middleName') && formRef.current.getValue('patientInfo.middleName')+' '}{formRef.current.getValue('patientInfo.lastName') + ' - '}
                  { formRef.current.getValue('patientInfo.dob') &&
                    new Date(formRef.current.getValue('patientInfo.dob'))
                      .toISOString()
                      .split('T')[0]
                      .split('-')
                      .slice(1)
                      .concat([new Date(formRef.current.getValue('patientInfo.dob')).toISOString().split('T')[0].split('-')[0]])
                      .join('/')
                  } - {GENDER_LABELS[formRef.current.getValue('patientInfo.gender')]}
                </p>
                <p>{formRef.current.getValue('patientInfo.address.address')+' - '}{formRef.current.getValue('patientInfo.address.address2') ? formRef.current.getValue('patientInfo.address.address2') +' - ': ''}{formRef.current.getValue('patientInfo.address.city')+', '}{formRef.current.getValue('patientInfo.address.state')+' '}{formRef.current.getValue('patientInfo.address.zipCode')} </p>
                <p>{formRef.current.getValue('patientInfo.address.country')}</p>
              </div>
              <div>
                <h4>Contact party</h4>
                <p>{formRef.current.getValue('contactDetails.contactRelationType') === PATIENT 
                  ? formRef.current.getValue('patientInfo.firstName')+' '+(formRef.current.getValue('patientInfo.middleName') ? formRef.current.getValue('patientInfo.middleName')+' ' : '')+formRef.current.getValue('patientInfo.lastName')
                  : formRef.current.getValue('contactDetails.fullName.first')+' '+formRef.current.getValue('contactDetails.fullName.last')
                } ({getRelationLabel(intl, formRef.current.getValue('contactDetails.contactRelationType'), formRef.current.getValue('contactDetails.otherRelationType'))})</p>
                <p>{formRef.current.getValue('contactDetails.phoneNumber')} - {formRef.current.getValue('contactDetails.email')}</p>
                <p>Preferred mode of communication: {formRef.current.getValue('contactDetails.prefCommunicationMode').toLowerCase()} {formRef.current.getValue('contactDetails.prefContactTime') ? <>, during the {formRef.current.getValue('contactDetails.prefContactTime').toLowerCase()}.</> : ''}</p>
              </div>
              {!isLegal && intakeFormsSteps.findIndex(step => step.label === 'Physician') !== -1 && formRef.current.getTransformedValues()?.referringPhysician && (formRef.current.getTransformedValues()?.referringPhysician?.fullName?.first || formRef.current.getTransformedValues()?.referringPhysician?.fullName?.last) && <div>
                <h4>Referring physician</h4>
                <p>
                  {formRef.current.getTransformedValues()?.referringPhysician?.fullName?.first ? formRef.current.getTransformedValues()?.referringPhysician?.fullName?.first + ' ' : ''}
                  {formRef.current.getTransformedValues()?.referringPhysician?.fullName?.last ? formRef.current.getTransformedValues()?.referringPhysician?.fullName?.last : ''}</p>
                <p>
                  {formRef.current.getTransformedValues()?.referringPhysician?.phoneNumber 
                    ? formRef.current.getTransformedValues()?.referringPhysician?.phoneNumber
                    : formRef.current.getTransformedValues()?.referringPhysician?.phoneNumber
                  } 
                  {formRef.current.getTransformedValues()?.referringPhysician?.email 
                    ? formRef.current.getTransformedValues()?.referringPhysician?.phoneNumber 
                      ? ' - ' + formRef.current.getTransformedValues()?.referringPhysician?.email 
                      : formRef.current.getTransformedValues()?.referringPhysician?.email
                    : ''
                  }
                </p>
                {formRef.current.getTransformedValues()?.referringPhysician?.institution && <p>Institution: {formRef.current.getTransformedValues()?.referringPhysician?.institution}</p>}
              </div>}
              {!isLegal && intakeFormsSteps.findIndex(step => step.label === 'Physician') !== -1 && formRef.current.getTransformedValues()?.localPhysician && (formRef.current.getTransformedValues()?.localPhysician?.fullName?.first || formRef.current.getTransformedValues()?.localPhysician?.fullName?.last) && <div>
                <h4>Local physician</h4>
                <p>
                  {formRef.current.getTransformedValues()?.localPhysician?.fullName?.first ? formRef.current.getTransformedValues()?.localPhysician?.fullName?.first + ' ': ''}
                  {formRef.current.getTransformedValues()?.localPhysician?.fullName?.last ? formRef.current.getTransformedValues()?.localPhysician?.fullName?.last : ''}
                </p>
                <p>
                  {formRef.current.getTransformedValues()?.localPhysician?.phoneNumber 
                    ? formRef.current.getTransformedValues()?.localPhysician?.phoneNumber
                    : formRef.current.getTransformedValues()?.localPhysician?.phoneNumber
                  } 
                  {formRef.current.getTransformedValues()?.localPhysician?.email 
                    ? formRef.current.getTransformedValues()?.localPhysician?.phoneNumber 
                      ? ' - ' + formRef.current.getTransformedValues()?.localPhysician?.email 
                      : formRef.current.getTransformedValues()?.localPhysician?.email
                    : ''
                  }
                </p>
                {formRef.current.getTransformedValues()?.localPhysician?.institution && <p>Institution: {formRef.current.getTransformedValues()?.localPhysician?.institution}</p>}
              </div>}
              {formRef.current.getValue('caseInfo.clinicalSummary') && <div>
                <IfLocale not contains="LEGAL">
                  <h4>Primary concern</h4>
                  {formRef.current.getValue('caseInfo.intakeDiagnosis') && <p> Primary diagnosis: {formRef.current.getValue('caseInfo.intakeDiagnosis')}</p>}
                  {formRef.current.getValue('caseInfo.hasBeenTreatedOrSurgeryBefore') 
                    ? <p> Patient has been treated or had surgery for this condition before: {formRef.current.getValue('caseInfo.treatedOrSurgeryBeforeDescription')}</p>
                    : <p> Patient has not been treated or had surgery for this condition before</p>
                  }
                  {formRef.current.getValue('caseInfo.clinicalSummary') && <p> Request description: {formRef.current.getValue('caseInfo.clinicalSummary')}</p>}
                </IfLocale>
                <IfLocale contains="LEGAL">
                  <h4>Case details</h4>
                  {formRef.current.getValue('caseInfo.clinicalSummary') && <p>{formRef.current.getValue('caseInfo.clinicalSummary')}</p>}
                </IfLocale>
              </div>}
            </CasePreview>
          </Dialog>}
        <PageFooter NoHR>
          <Button highlight onClick={accept} isLoading={isSubmitting}>Create case</Button>
        </PageFooter>
      </PageContainer>
      {acceptJurisdictionWarning.isOpen && <Dialog
        title="Attention"
        closeDialog={acceptJurisdictionWarning.close}
        footer={<> 
          <Button onClick={acceptJurisdictionWarning.close}>Cancel</Button>
          <Button highlight onClick={() => {
            acceptJurisdictionWarning.close()
            // formRef.current.willSubmitForm()
            setIsPreview(true)
            }}>Proceed</Button>
        </>}
      >
        <p><b>{formRef.current.getValue('patientInfo.address.state')} - {formRef.current.getValue('patientInfo.address.country')}</b> is not within your accepted jurisdictions.</p>
        <p>Do you want to proceed?</p>
      </Dialog>}
    </PageContent>
  </PageWrapper>
}

