import {AutoComplete} from '@startlibs/form'
import {Button} from '@startlibs/components'
import React from 'react'
import _ from 'lodash/fp'
import {ConfirmDialog, useConfirmDialogWithProps} from '../../../hooks/useConfirmDialog'
import {buildValidation} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../../../utils/authFetch';
import {wrapPromise} from '../../../hooks/useSuspense'
import {callIfFunction} from '@startlibs/utils'
import {autocompleteFetch} from '../../../utils/utils'
import { css } from 'styled-components'

const getListItem = (v) => v.name
const validateTag = (v) => ({errors: "Please select a valid category from the list."})
const getLabel = (v) => v.name

export const lazyPublicCategories = wrapPromise(() => jwtGetFetcher()("/api/categories"))
export const lazyUserCategories = wrapPromise(() => jwtGetFetcher(getJwt())("/api/admin/categories"))

const preValidation = buildValidation({
  categories: (list) => list.find(_.isString) && "Please select a valid category from the list."
})

const getId = ({requestId, id}) => requestId || id

export const useConfirmMissingCategories = (caseRequest, onSuccess) => useConfirmDialogWithProps((values) =>
  <ConfirmDialog
    action={(categories) => jwtPostFetcher(getJwt())('/api/admin/caseCategory?requestId=' + getId(callIfFunction(caseRequest)), categories.map(_.get('id')), {method: "PUT"})}
    title="Confirm changing case division(s)"
    notify="Divisions updated successfully."
    values={values}
    onSuccess={onSuccess}
    confirm={<Button alert>Confirm & lose access to case</Button>}
  >
    <p>
      If you remove these divisions from this case <strong>you will lose access to it. </strong>
      Consider keeping at least one division you have access to.
    </p>
    <p>Are you sure you want to apply the changes and <strong>lose access to this case</strong>?</p>
  </ConfirmDialog>
)

export const useEditCategories = (
  userInfo, onSuccess,
    {
      saveAction = (caseRequest, categories) => jwtPostFetcher(getJwt())('/api/admin/caseCategory?requestId=' + getId(caseRequest), categories.map(_.get('id')), {method: "PUT"}),
      notify = "Divisions updated successfully.",
      lazyCategories
    } = {}
) => {


  const action = (categories) => {
    const newIds = categories.map(_.get('id'))
    if (!userInfo.categories.length || userInfo.categories.find(cat => newIds.indexOf(cat.id) >= 0)) {
      return saveAction(toggleWithCaseRequest.get(), categories)
    } else {
      confirmDialog.openWith(categories)
      return Promise.reject([])
    }
  }


  const toggleWithCaseRequest = useConfirmDialogWithProps((caseRequest) => <ConfirmDialog
      action={action}
      confirm={<Button highlight>Update</Button>}
      preValidation={preValidation}
      transform={({categories}) => categories.filter(_.identity)}
      values={{categories: caseRequest.categories}}
      isLoading={false}
      onSuccess={onSuccess(caseRequest)}
      notify={notify}
      title="Edit case divisions"
    >{form => <>
      <p>Manage the divisions that can access this case.</p>
      <CategoriesBox lazyCategories={lazyCategories} form={form}/> </>
    }</ConfirmDialog>
  )

  const confirmDialog = useConfirmMissingCategories(() => toggleWithCaseRequest.get(), (values) => {
    onSuccess(toggleWithCaseRequest.get())(values);
    toggleWithCaseRequest.close()
  })

  return toggleWithCaseRequest
}

export const CategoriesBox = ({autoFocus = true, userRelated, lazyCategories = lazyUserCategories, mandatory, minWidth = false}) => {

  lazyCategories.read()

  return <CategoriesInput
    userRelated={userRelated}
    autoFocus={autoFocus}
    lazyCategories={lazyCategories}
    mandatory={mandatory}
    minWidth={minWidth}
  />
}

export const CategoriesInput = ({autoFocus, mandatory, userRelated, lazyCategories = lazyUserCategories, minWidth}) =>
<div 
  onClick={e => e.preventDefault()}
  onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
>
  <AutoComplete
    // CompletedBox={AutocompleteBox}
    mandatory={mandatory}
    minQueryLength={1}
    autoFocus={autoFocus}
    label="Divisions"
    confirmIfValueKeys={['Tab']}
    path='categories'
    clickOpensWhenEmpty
    value={[]}
    autoSelect
    fetchSuggestions={autocompleteFetch(lazyCategories)}
    validation={validateTag}
    getLabel={getLabel}
    withDropdown
    bellowDescText="Use comma, enter or tab to add multiple."
    placeholder="Click or start typing and select from the list"
    css={minWidth && `min-width: ${minWidth}`}
  />
</div>
