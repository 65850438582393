import { Icon, SplitColumnsContainer } from '@startlibs/components'
import { Combobox, Errors, FormValue, RadioboxGroup, SimpleCheckbox, SimpleRadiobox, TextInput,  useFormValue } from '@startlibs/form'
import { getColor } from '@startlibs/utils';
import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Card } from '../../components/PageLayout';
import { LOCAL_PHYSICIAN, REF_PHYSICIAN } from '../../enums/ContactRelationType'
import { ConfirmEmailInput } from '../../components/ConfirmEmailInput'
import { getFormattedStates } from '../PatientAndContactDetailsNew';
import { ComboboxContainer } from './ContactDetailsNew';
import { applyPhoneMask } from '../forms/utils';
import { AddButton } from '../../components/AddButton';
import StyledTelInput1 from '../../components/StyledTelInput1';
import { IntakeFormCard } from './PatientDetailsNew';

export const AddressHeading = styled.div`
  font-size: 13px;
  color: ${getColor('gray150')};
  padding-bottom: .25rem;
  border-bottom: 1px solid ${getColor('gray210')};
  margin: 1.5rem 0;
`

const VIEWPORT_MIN_WIDTH = 500

const CloseIcon = styled(Icon)`
  position: absolute;
  right: -0.25rem;
  top: -0.25rem;
  color: #f0f0f0;
  background: #787878;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
  padding: 4px;
  font-size: 18px;
  z-index: 999;
  padding: 0;
  :hover {
    background: #6b6b6b;
  }
`

export const ReferringPhysicianNew = ({ title, subTitle, readOnly, isAdmin, form, countryList, showSelects, 
  physicianPhoneNumber1Valid, setPhysicianPhoneNumber1Valid,
  physicianFaxNumber1Valid, setPhysicianFaxNumber1Valid,
  physicianPhoneNumber2Valid, setPhysicianPhoneNumber2Valid,
  physicianFaxNumber2Valid, setPhysicianFaxNumber2Valid,
  NavigationButtons}) => {

  const [relationType] = useFormValue('contactDetails.contactRelationType')

  useEffect(() => {
    // if there is no physician, add one
    if (!form.getValue('referringPhysicians') || form.getValue('referringPhysicians').length === 0) {
      form.setValue('referringPhysicians', [{
        role:  (relationType === LOCAL_PHYSICIAN || relationType === REF_PHYSICIAN) 
          ? relationType === LOCAL_PHYSICIAN 
            ? REF_PHYSICIAN 
            : LOCAL_PHYSICIAN 
          : REF_PHYSICIAN
      }]);
    }
    if(form.getValue('referringPhysicians').length === 1){
      if(relationType === LOCAL_PHYSICIAN || relationType === REF_PHYSICIAN){
        form.setValue('referringPhysicians[0].role',  relationType === LOCAL_PHYSICIAN ? REF_PHYSICIAN : LOCAL_PHYSICIAN);
      }
    }

  }, [])
  // Function to add a new physician
  const addPhysician = () => {
    const physicians = form.getValue('referringPhysicians') || [];
    let nextPhysicianRole = '';
    if (physicians.length > 0) {
      if(physicians[0].role === REF_PHYSICIAN) {
        nextPhysicianRole = LOCAL_PHYSICIAN;
      }else{
        nextPhysicianRole = REF_PHYSICIAN
      }
      form.setValue('referringPhysicians', [...physicians, {role: nextPhysicianRole}]);  
    }
    
  };

  // Function to remove a physician
  const removePhysician = (index) => {
    const physicians = form.getValue('referringPhysicians') || [];
    const newPhysicians = physicians.filter((physician, i) => i !== index);
    form.setValue('referringPhysicians', newPhysicians);
  };
  
  const canHaveTwoPhysicians = relationType !== LOCAL_PHYSICIAN && relationType !== REF_PHYSICIAN
  // if (relationType === REF_PHYSICIAN) {
  //   return null
  // }

  return <>
    {/* <SectionHeading>
      <h3>Referring physician</h3>
      <p>
        This physician can be requested to take action on this case and may
        receive a copy of any resulting reports.
      </p>
    </SectionHeading> */}
    <IntakeFormCard>
      <div>
      {title && <h3>{title}</h3>}
      {subTitle && <p>{subTitle}</p>}
      <FormValue path="referringPhysicians">{
        (referringPhysicians = [{}]) => 
          referringPhysicians.map((physician, index) => ( 
            <div key={index} 
              style={{
                backgroundColor: index === 0 ? 'white' : '#f2f2f2', 
                padding: index === 0 ? '0px' : '1rem', 
                marginBottom: index === 0 ? '1rem' : '0.5rem',
                borderRadius: index === 0 ? '0px' : '6px',
                border: index === 0 ? 'none' : '1px solid #d9d9d9',
                position: index === 0 ? 'inherit' : 'relative'
              }}
            >
              {index === 1 && <CloseIcon icon='x' 
                // style={{
                //   position: 'absolute', right: '1rem', top: '0rem',
                //   cursor: 'pointer',
                //   background: 'black',
                //   color: 'white',
                //   borderRadius: '60px',
                //   padding: '4px',
                //   width: '18px',
                //   height: '18px',
                //   textAlign: 'center',
                //   fontSize: '18px',
                //   cursor: 'pointer',
                //   zIndex: 999
                // }} 
                onClick={() => {
                  console.log('remove physician')
                  removePhysician(index)} 
                }/>}
              <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">

                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.fullName.first"
                  path={`referringPhysicians[${index}].fullName.first`}
                  label="First name"
                  placeholder="Ex: John"
                  maxLength={255}
                />

                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.fullName.last"
                  path={`referringPhysicians[${index}].fullName.last`}
                  label="Last name"
                  placeholder="Ex: Doe"
                  maxLength={255}
                />
              </SplitColumnsContainer>
              <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
                {/* <TextInput
                  disabled={readOnly}
                  type="text"
                  path="referringPhysician.institution"
                  label="Physician role"
                  placeholder="Ex: Purview"
                  maxLength={255}
                /> */}
                <RadioboxGroup 
                  disabled={readOnly} 
                  label="Physician role" 
                  horizontal 
                  // path="referringPhysician.role"
                  path={`referringPhysicians[${index}].role`}
                  // _PHYSICIAN
                  framedBoxes 
                  wide
                >
                  <SimpleRadiobox fieldValue={REF_PHYSICIAN} label="Referring" disabled={relationType === REF_PHYSICIAN}/>
                  <SimpleRadiobox fieldValue={LOCAL_PHYSICIAN} label="Local" disabled={relationType === LOCAL_PHYSICIAN} />
                </RadioboxGroup>
                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.institution"
                  path={`referringPhysicians[${index}].institution`}
                  label="Institution"
                  placeholder="Ex: Purview"
                  maxLength={255}
                />
              </SplitColumnsContainer>
              <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
                {showSelects ? <>
                  <ComboboxContainer readOnly={readOnly} className='fs-exclude'><Combobox
                    disabled={readOnly}
                    className="combobox fs-exclude"
                    placeholder="Please select country"
                    label={"Country"}
                    // path='referringPhysician.address.country'
                    path={`referringPhysicians[${index}].address.country`}
                    options={countryList}
                    onChange={(val) => {
                      var states = getFormattedStates(val)
                      if (states.length > 0) {
                        // if (states.findIndex(x => x === form.getValue('referringPhysician.address.state')) == -1) {
                        if (states.findIndex(x => x === form.getValue(`referringPhysicians[${index}].address.state`)) == -1) {
                          // form.setValue('referringPhysician.address.state', null)
                          form.setValue(`referringPhysicians[${index}].address.state`, null)
                        }
                      }
                    }}
                  /></ComboboxContainer>
                  {/* <FormValue path="referringPhysician.address.country"> */}
                  <FormValue path={`referringPhysicians[${index}].address.country`}>{country =>
                    <ComboboxContainer readOnly={readOnly} className='fs-exclude'>
                      <Combobox
                        disabled={readOnly}
                        className="combobox fs-exclude"
                        placeholder="Please select state"
                        label={"State/Province"}
                        // path='referringPhysician.address.state'
                        path={`referringPhysicians[${index}].address.state`}
                        options={getFormattedStates(country)}
                      // onChange={(val) => setRegion(val)}
                      />
                    </ComboboxContainer>
                  }</FormValue>
                </> : <>
                  <TextInput
                    disabled={readOnly}
                    type="text"
                    // path="referringPhysician.address.country"
                    path={`referringPhysicians[${index}].address.country`}
                    label="Country"
                    placeholder="Ex: USA"
                    maxLength={255}
                  // mandatory
                  />
                  <TextInput
                    disabled={readOnly}
                    type="text"
                    // path="referringPhysician.address.state"
                    path={`referringPhysicians[${index}].address.state`}
                    label="State"
                    placeholder="Ex: New York"
                    maxLength={255}
                  // mandatory
                  />
                </>}
              </SplitColumnsContainer>
              <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.address.city"
                  path={`referringPhysicians[${index}].address.city`}
                  label="City"
                  placeholder="Ex: New York"
                  maxLength={255}
                // mandatory
                />
                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.address.zipCode"
                  path={`referringPhysicians[${index}].address.zipCode`}
                  label="Postal code"
                  placeholder="Ex: 12345"
                  maxLength={255}
                // mandatory
                />
              </SplitColumnsContainer>
              <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.address.address"
                  path={`referringPhysicians[${index}].address.address`}
                  label="Street address - Line 1"
                  placeholder="Ex: 001 Street Name"
                  maxLength={255}
                // mandatory
                />
                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.address.address"
                  path={`referringPhysicians[${index}].address.address2`}
                  label="Street address - Line 2"
                  placeholder="Ex: PO Box 001"
                  maxLength={255}
                // mandatory
                />
              </SplitColumnsContainer>
              <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
                <TextInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.email"
                  path={`referringPhysicians[${index}].email`}
                  label="Email address"
                  placeholder="Ex: physicians@email.com"
                  maxLength={255}
                />
                <ConfirmEmailInput
                  disabled={readOnly}
                  type="text"
                  // path="referringPhysician.confirmEmail"
                  path={`referringPhysicians[${index}].confirmEmail`}
                  confirmingPath={`referringPhysicians[${index}].email`}
                  // confirmingPath="referringPhysician.email"
                  label="Confirm email"
                  placeholder="Ex: physicians@email.com"
                  maxLength={255}
                  mandatory={false}
                  canLock={false}
                />
              </SplitColumnsContainer>
              <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
                
                <StyledTelInput1
                  // mandatory 
                  // path={`referringPhysicians[${index}].phoneNumber`}
                  path={['referringPhysicians', index, 'phoneNumber']}
                  hasErrors={form.hasErrors()}
                  label="Phone number"
                  placeholder="(201) 555-0123"
                  disabled={readOnly}
                  initOptions={{
                    initialCountry: "us",
                    separateDialCode: false,
                    autoPlaceholder: "aggressive",
                    formatOnDisplay: true,
                    placeholderNumberType: "FIXED_LINE_OR_MOBILE",
                    nationalMode: false,
                    countryOrder: ["us"],
                    validationNumberType: "FIXED_LINE_OR_MOBILE"
                  }}
                  tabIndex={1}
                  isValid={index === 0 ? physicianPhoneNumber1Valid : physicianPhoneNumber2Valid}
                  setIsValid={index === 0 ? setPhysicianPhoneNumber1Valid : setPhysicianPhoneNumber2Valid}
                />
                <StyledTelInput1
                  // mandatory 
                  path={`referringPhysicians[${index}].faxNumber`}
                  hasErrors={form.hasErrors()}
                  label="Fax number"
                  placeholder="(201) 555-0123"
                  disabled={readOnly}
                  initOptions={{
                    initialCountry: "us",
                    separateDialCode: false,
                    autoPlaceholder: "aggressive",
                    formatOnDisplay: true,
                    placeholderNumberType: "FIXED_LINE_OR_MOBILE",
                    nationalMode: false,
                    countryOrder: ["us"],
                    validationNumberType: "FIXED_LINE_OR_MOBILE"
                  }}
                  tabIndex={1}
                  isValid={index === 0 ? physicianFaxNumber1Valid : physicianFaxNumber2Valid}
                  setIsValid={index === 0 ? setPhysicianFaxNumber1Valid : setPhysicianFaxNumber2Valid}
                />
                  {isAdmin && <TextInput
                    disabled={readOnly}
                    type="text"
                    path={`referringPhysicians[${index}].npi`}
                    // path="referringPhysician.npi"
                    label="NPI"
                    placeholder="Ex: 1234567890"
                    maxLength={255}
                    // mandatory
                  />}
              </SplitColumnsContainer>
              {/* <FormValue path="referringPhysician.acceptContact"> */}
              <FormValue path={`referringPhysicians[${index}].acceptContact`}>{acceptContact =>
                acceptContact !== null && <>
                  {isAdmin && <SplitColumnsContainer viewportMinWidth={VIEWPORT_MIN_WIDTH} margin="big">
                    <SimpleCheckbox
                      disabled={readOnly}
                      // path="referringPhysician.acceptContact"
                      path={`referringPhysicians[${index}].acceptContact`}
                      label={<span css="font-size:13px;">This physician has been made aware of this case, and can be contacted as necessary</span>}
                    />
                  </SplitColumnsContainer>}
                </>}
              </FormValue>
              {index === 0 && referringPhysicians.length < 2 && canHaveTwoPhysicians && <AddButton css="margin-top:2rem;" onClick={addPhysician}>Add a physician with another role </AddButton>}
            </div>
          )
        ) 
      }</FormValue>
      </div>
      <Errors path="referringPhysicians" />
      <NavigationButtons /> 
    </IntakeFormCard>
  </>
}
