import React, { forwardRef, useEffect, useRef, useState } from 'react';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import styled from 'styled-components';
import { useFormValue } from '@startlibs/form';

// Styled components
const StyledIntlTelInput = styled(IntlTelInput)`
  width: 100%;
  div {
    width: 100%;
  }
`;

const HelpTextSpan = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-weight: 400;
  font-size: 14px;
  padding-left: 0.25rem;
`;

const ErrorTextSpan = styled.span`
  color: #c3282d;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 0.5rem;
`;

const FloatLabelIntlTelInput = styled.div`
  position: relative;
  width: 100%;
  input {
    width: 100%;
    font-size: 14px;
    border: 1px solid #d2d2d2;
    ${(props) => (props.hasError ? 'border-color: #c3282d;' : '')}
    border-radius: 6px;
    outline: 0;
    color: #3c3c3c;
    background: white;
    height: 3rem;
    padding: 0 0.7rem;
    overflow: auto;
    ::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }
     ::-webkit-input-placeholder {
      color: rgba(0,0,0,0.3);
    }
    ::-moz-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :-moz-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :-ms-input-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :focus {
      border-color: #b4b4b4;
      box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
    }
      
  }
  label {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 0.5rem;
    font-weight: 600;
    color: #3C3C3C;
  }
`;

const Mandatory = styled.span`
  color: #c3282d;
  margin-left: 1px;
  vertical-align: top;
  line-height: 100%;
  margin-top: -1px;
`;

export const StyledTelInput1 = forwardRef(({
  label,
  path,
  placeholder,
  initOptions,
  tabIndex = 0,
  disabled = false,
  helpText = '',
  mandatory = false,
  hasErrors = false,
  setIsValid = false,
  isValid = true,
  usePreciseValidation = false,
  ...props
}, ref) => {
  // Call `useFormValue` at the top level
  const [value, setValue] = useFormValue(path);
  
  // Local state for value
  const [localValue, setLocalValue] = useState(value);
  const intlTelInputRef = useRef(null);
  const [hasUserTyped, setHasUserTyped] = useState(false);

  // Sync localValue with form context value on mount or path change
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  // Update form value when localValue changes
  useEffect(() => {
    if (hasUserTyped) {
      setValue(localValue);
    }
  }, [localValue, hasUserTyped, setValue]);

  // Track user input and handle number changes
  const handleOnChangeNumber = (val) => {
    if (!disabled) {
      setLocalValue(val);
      setHasUserTyped(true);
    }
  };

  const handleOnCountryChange = () => setHasUserTyped(true);
  const handleOnKeyUp = () => setHasUserTyped(true);

  // Manage tabindex and event listeners
  useEffect(() => {
    if (intlTelInputRef.current) {
      const inputElement = intlTelInputRef.current.querySelector('input.iti__tel-input');
      if (inputElement) {
        inputElement.setAttribute('tabindex', tabIndex);
        inputElement.addEventListener('keyup', handleOnKeyUp);
        inputElement.addEventListener('countrychange', handleOnCountryChange);

        return () => {
          inputElement.removeEventListener('keyup', handleOnKeyUp);
          inputElement.removeEventListener('countrychange', handleOnCountryChange);
        };
      }
    }
  }, [tabIndex]);

  // Error handling
  const hasError = hasErrors && (
    ((!localValue && mandatory) || (localValue === '' && mandatory))
    || 
    (localValue && !isValid)
  );

  return (
    <FloatLabelIntlTelInput hasError={hasError}>
      {label && (
        <>
          <label>
            {label}
            {mandatory && <Mandatory>*</Mandatory>}
          </label>
          {helpText && <HelpTextSpan>{helpText}</HelpTextSpan>}
        </>
      )}
      <div ref={intlTelInputRef} style={{ width: '100%' }}>
        <StyledIntlTelInput
          {...props}
          ref={ref}
          value={localValue}
          initialValue={localValue}
          usePreciseValidation={usePreciseValidation}
          onChangeValidity={(isValid) => {
            if(setIsValid && hasUserTyped){
              setIsValid(isValid)
            }
            // setErrorMessage(isValid ? '' : 'Invalid phone number');
          }}
          onChangeNumber={handleOnChangeNumber}
          onKeyUp={handleOnKeyUp}
          placeholder={placeholder || '(201) 555 0123'}
          initOptions={initOptions}
          autoComplete="tel"
          disabled={disabled}
        />
      </div>
      {/* {hasError && <ErrorTextSpan>Invalid input</ErrorTextSpan>} */}
    </FloatLabelIntlTelInput>
  );
});

export default StyledTelInput1;
