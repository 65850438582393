import {Button, Icon, Link} from '@startlibs/components'
import { BellowFieldDescription, CheckboxGroup, FormValue, SimpleCheckbox, TextInput } from '@startlibs/form';
import React from 'react'
import {FormattedMessage} from 'react-intl'
import _ from 'lodash/fp'
import {CaseRequestCard} from '../../CaseRequestCard'
import {
  ConfirmDialog,
  useConfirmDialogWithProps
} from '../../../hooks/useConfirmDialog';
import {ContactSelectBoxes, SelectorTitle} from '../RecordsStep'
import {ContactSelectInput} from '../../../components/ContactSelectInput'
import {PRIMARY} from '../../../enums/ContactRelationKind'
import {LOCAL_PHYSICIAN, REF_PHYSICIAN} from '../../../enums/ContactRelationType'
import {CASE_CLOSED, CASE_REVIEWED, PENDING_REQUEST, WAITING_ACCEPTANCE} from '../../../enums/CaseState'
import {buildValidation, required} from '../../../utils/validation'
import {getJwt} from '../../../hooks/useJwt'
import {jwtPostFetcher} from '../../../utils/authFetch'
import { IfLocale } from '../../../hocs/IfLocale';
import { lazyProviderInfo } from '../../../components/WithProvider';
import { hasAcceptedAllTerms, lazyTerms } from '../../../patient/utils/caseRequestUtils';
import { DIGITAL } from '../../../enums/PaymentMethod';
import { isStateBefore } from '../../../request/utils';
import { MarkAsReadButton, PaymentRequestedTag, TermsInfo } from '../ContactPatientStep';
import { InfoBox } from '../../../components/InfoBox';

const onlyPayment = (requestFlags) => requestFlags.askForPayments && Object.values(requestFlags).filter(_.identity).length === 1

export const useConfirmAskPatientUpdate = (caseRequest, setCaseRequest, isNotLegal, setShouldReloadData) => useConfirmDialogWithProps((requestFlags) =>  {

  const providerInfo = lazyProviderInfo.read()
  
  var allTermsAccepted = ''
  if(providerInfo?.serviceTerms === true){
    allTermsAccepted = hasAcceptedAllTerms(lazyTerms.read(),caseRequest)
  }else{
    allTermsAccepted = true
  }
  const isPending = caseRequest.state === PENDING_REQUEST

  const needsPayment = !!caseRequest.payments.find(payment => (payment?.paymentMethod === DIGITAL && payment?.chargeValue > 0) && !payment?.paid && !payment?.revoked)
  const needsAgreement = isNotLegal && !caseRequest.acceptanceInfo.acceptedTerms && (providerInfo?.requiresAgreement === true) && (providerInfo?.serviceTerms === true)
  
  const preValidation = buildValidation({
    'title': (v) => !v && required(),
    'message': [
      (v) => !v && required(),
      (v) => v?.length > 3000 && 'Message: Maximum character limit of 3000 reached.'
    ],
    contactRelationType: (v) => !v && 'Please choose a contact before proceeding.',
  })

  const wasSentToExpert = !isStateBefore(caseRequest.state)(WAITING_ACCEPTANCE)
  const canUnassign = isStateBefore(caseRequest.state)(CASE_REVIEWED)

  const requestedPayment = caseRequest.caseFlags?.requestFlags?.askForPayments

  const referringPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "REF_PHYSICIAN" && caseRequest.referringPhysician.email
  const localPhysicianAsSecondary = caseRequest.contactDetails.contactRelationType !== "LOCAL_PHYSICIAN" && caseRequest.localPhysician.email

  return <ConfirmDialog
    title={"Message case contact"}
    confirmChanges={false}
    confirm={<Button highlight icon="email" css="min-width:8rem;">Send</Button>}
    values={{
      contactRelationType: caseRequest.referringPhysician.email ? undefined : caseRequest.contactDetails.contactRelationType,
      message: '',
      title: 'Case contact updates requested',
      requestFlags: 
      isPending
        ? {askedPatientDetails: false} // true
        : {
          askedRecords: false, //wasSentToExpert ? false : true,
          askForPayments: isNotLegal && needsPayment,
          askForServiceTerms: needsAgreement
        }
    }}
    action={(values) => 
      {
        return onlyPayment(values.requestFlags) ? jwtPostFetcher(getJwt())('/api/admin/requestPatientPayment?requestId=' + caseRequest.requestId, values) : jwtPostFetcher(getJwt())('/api/admin/contactPatient?requestId=' + caseRequest.requestId, values)
      }
    }
    preValidation={preValidation}
    onSuccess={(values) => {
      setShouldReloadData(true)
    }}
    notify={onlyPayment(requestFlags) ? "Payment requested successfully." : "Updates requested successfully."}
  >{form => <>
    {/* <p>
      <FormattedMessage defaultMessage="You are about to request that the indicated contact complete the previously selected actions on this case:" description="useConfirmAskPatientUpdate subtitle"/>
    </p> */}
    <CaseRequestCard
      viewOnly
      caseRequest={caseRequest}
    />
    {
      referringPhysicianAsSecondary || localPhysicianAsSecondary
      ? <>
          <SelectorTitle><span>Select a contact:</span> <Link
            className="link"
            to={`/admin/request/${caseRequest.requestCode}/patient`}
            state={{scrollToContact:true}}
          >Edit contacts</Link></SelectorTitle>
          <ContactSelectBoxes path="contactRelationType">
            <ContactSelectInput
              fieldValue={caseRequest.contactDetails.contactRelationType}
              caseRequest={caseRequest}
              relationKind={PRIMARY}
            />
            {referringPhysicianAsSecondary && <ContactSelectInput
              fieldValue={REF_PHYSICIAN}
              caseRequest={caseRequest}
              relationKind={REF_PHYSICIAN}
            />}
            {localPhysicianAsSecondary && <ContactSelectInput
              fieldValue={LOCAL_PHYSICIAN}
              caseRequest={caseRequest}
              relationKind={LOCAL_PHYSICIAN}
            />}
          </ContactSelectBoxes> 
        </>
      : <>
          <p css="margin: 1rem 0 0.5rem;">
            {/* <FormattedMessage defaultMessage="An e-mail will be delivered to the patient primary contact with your request:" description='Dialog desc text email to case contact with request' /> */}
            An e-mail will be delivered to the case contact with your request
          </p>
        <ContactSelectBoxes path="contactRelationType">
          <ContactSelectInput
            raw
            value={true}
            fieldValue={true}
            caseRequest={caseRequest}
            relationKind={PRIMARY}
            viewOnly
          >
          </ContactSelectInput>
        </ContactSelectBoxes>
        <div css="margin: -0.5rem 0 1rem;"><Link
          className="link"
          state={{scrollToContact:true}}
          to={`/admin/request/${caseRequest.requestCode}/patient`}
        >Edit contacts</Link></div>
      </>
    }
    <TextInput
      path="title"
      mandatory={true}
      placeholder="Enter a subject"
      label="Subject"
      maxLength={255}
    />
    <TextInput
      textarea
      autoResize
      label="Message"
      path="message"
      placeholder="Type here..."
      onChange={(e) => {
        if(e.length > 3000){
          form.addErrors({message: 'Message: Maximum character limit of 3000 reached.'})
        }else{
          form.clearErrors('message')
        }
      }}
      bellowDescText={
        <FormValue path="message">{message => {
          if(message){
            return <span style={{ 
              display: 'block',
              width: '100%',
              textAlign: 'right'
            }}>{message?.length+"/3000"}</span>
          }
        }}</FormValue>
      }
    />
    <IfLocale not contains="LEGAL">
      {
        wasSentToExpert  &&
        (needsPayment || !allTermsAccepted
            ? <InfoBox
                lightBlue
                css="margin-bottom: 2rem;"
              >
                Since this case has been {caseRequest.state === CASE_CLOSED ? "completed " : "assigned to an expert "}, you are not able to request updates other than payment or agreements.
              </InfoBox>
            : <div>
                <InfoBox lightBlue>
                  This case has been {caseRequest.state === CASE_CLOSED ? "completed " : "assigned to an expert "} and no updates can be requested to the patient at the moment.
                </InfoBox>
                <div css="margin-bottom: 1rem;">
                  {caseRequest.payments.length < 1 ?
                    <span>You can still <Link
                      className="link"
                      // to="../payment"
                      to={`/admin/request/${caseRequest.requestCode}/payment`}
                    >add or manage payments</Link> at this time</span>
                    : (canUnassign &&
                      <span>If you need to request updates to the patient please unassign this case from the expert.</span>)
                  }
                </div>
              </div>
        )
      }
    </IfLocale>
    {
      wasSentToExpert
        ? <>
          <IfLocale not contains="LEGAL">
            {(needsPayment || !allTermsAccepted) && 
              <CheckboxGroup
                framedBoxes highlightSelected
                label="Request updates"
                helpText="(Optional)"
              > 
                {needsPayment &&
                  <SimpleCheckbox
                    css="margin-top: .5rem;" disabled={!needsPayment} path="requestFlags.askForPayments" label={
                      <>Provide payment {requestedPayment &&
                      <PaymentRequestedTag><Icon icon="clock"/> Waiting on payment</PaymentRequestedTag>}</>
                    }
                  />
                }
                {(!allTermsAccepted && providerInfo?.serviceTerms === true) &&
                  <SimpleCheckbox
                    css="margin-top: .5rem;"
                    path="requestFlags.askForServiceTerms"
                    label={
                      <span css="padding-right:10rem">
                        Sign the required <Link
                        className="link"
                        to={`/admin/request/${caseRequest.requestCode}/agreements`}
                      >agreements</Link>
                        <MarkAsReadButton
                          as={Link}
                          small
                          to={`/admin/request/${caseRequest.requestCode}/agreements`}
                        >Review agreements</MarkAsReadButton>
                      </span>
                    }
                  />
                }
              </CheckboxGroup>
            }
          </IfLocale>
          <IfLocale contains="LEGAL">
            <div>
              <InfoBox lightBlue>This case has been {caseRequest.state === CASE_CLOSED ? "completed " : "assigned to an expert "} and no updates can be requested to the client at the moment.</InfoBox>
            </div>
          </IfLocale>
          </>
        : <CheckboxGroup
          framedBoxes highlightSelected
          label="Request updates"
          helpText="(Optional)"
        >
          {(isNotLegal && !allTermsAccepted && providerInfo.serviceTerms) &&
            <SimpleCheckbox
              css="margin-top: .5rem;"
              path="requestFlags.askForServiceTerms"
              label={
                <span css="padding-right:10rem">
                  Sign the required <Link
                  className="link"
                  to={`/admin/request/${caseRequest.requestCode}/agreements`}
                >agreements</Link>
                  <MarkAsReadButton
                    as={Link}
                    small
                    to={`/admin/request/${caseRequest.requestCode}/agreements`}
                  >Review agreements</MarkAsReadButton>
                </span>
              }
            />
          }
          <SimpleCheckbox
            css="margin-top: .5rem;"
            path="requestFlags.askedPatientDetails"
            label={<FormattedMessage defaultMessage="Update patient & contact details" description="ContactPatientStep askPatientDetails checkbox label"/>}
          />
          <SimpleCheckbox path="requestFlags.askedRecords" label="Medical records and releases"/>
          {isNotLegal && <SimpleCheckbox
              disabled={!needsPayment} path="requestFlags.askForPayments" label={
                <>Provide payment {requestedPayment &&
                <PaymentRequestedTag><Icon icon="clock"/> Waiting on payment</PaymentRequestedTag>}</>
              }
            />
          }
        </CheckboxGroup>
    }
  
    {
      (isNotLegal && allTermsAccepted && providerInfo.serviceTerms) &&
      <TermsInfo caseRequest={caseRequest} setCaseRequest={setCaseRequest}/>
    }  
    {!onlyPayment(requestFlags) && <BellowFieldDescription css="margin:-1rem 0 1rem;">
      {/* <FormattedMessage defaultMessage='If selected, you will not be able to update the "Patient & contact details" of this case until the contact is completed or the request is revoked.' description='useConfirmAskPatientUpdate request observation'/> */}
      <FormattedMessage 
        defaultMessage="Please note: If 'Update patient & contact details' is selected in the requested updates, you will not be able to modify the 'Patient & contact details' for this case until the case contact has completed the action or the request is revoked." 
        description='useConfirmAskPatientUpdate request observation'
      />
    </BellowFieldDescription>}
  </>}
  </ConfirmDialog>
}
)
