import {Button, Dialog} from '@startlibs/components'
import {Errors} from '@startlibs/form'
import {useNavigate} from 'react-router'
import React, {Suspense, useEffect, useRef, useState} from 'react'
import _ from 'lodash/fp'
import {Card, PageContainer, PageFooter, SectionHeading} from '../components/PageLayout';
import {CategoriesBox, lazyUserCategories} from './experts/hooks/useEditCategories'
import {Header} from '../components/Header'
import {OptionalHeading} from '../request/forms/InsuranceDetails';
import {PageContent, PageWrapper} from './steps/RequestSidebar';
import {PatientAndContactDetails} from '../request/PatientAndContactDetails'
import {getJwt} from '../hooks/useJwt'
import {jwtGetFetcher, jwtPostFetcher} from '../utils/authFetch'
import {ConfirmDialog, useConfirmDialogWithProps} from '../hooks/useConfirmDialog'
import {lazyProviderInfo, lazyUserInfo} from '../components/WithProvider'
import {CreateRequestSidebar} from './steps/CreateRequestSidebar'
import {useToggle} from '@startlibs/core'
import {createCaseTransform, createLegalCaseTransform} from '../request/utils/createCaseUtils'
import { useIntl } from 'react-intl'
import { CountryRegionData } from 'react-country-region-selector'
import { convertToCareQualityGender } from '../enums/GenderCarequality'

export const AdminCreateRequest = () => {
  const navigate = useNavigate()
  const categories = useToggle([])
  const markedAsPriority = useToggle()
  const formRef = useRef()
  const intl = useIntl()

  const [acceptedJurisdictions, setAcceptedJurisdictions] = useState([])
  const acceptJurisdictionWarning = useToggle()

  useEffect(() => {
  
    jwtGetFetcher(getJwt())("/api/admin/acceptedJurisdictions").then(jurisdictions => {

      const transformedJurisdictions = jurisdictions.map(jurisdiction => {
        let newJurisdiction = jurisdiction
        
        newJurisdiction.allAvailableStates = 
          CountryRegionData
            .find(country => {
              return country[0] === jurisdiction.country;
            })[2].split("|").map(state => state.split("~")[0]);
        
        return newJurisdiction;
      });
      setAcceptedJurisdictions(transformedJurisdictions);
      
    })
    .catch(e => {
      console.log(e)
      // setNotification({ type: "alert", msg: "Failed to load accepted jurisdictions" })
    })
    
  }, [])

  const accept = () => {

    // const country = formRef.current.getValue('patientInfo.address.country')
    // const state = formRef.current.getValue('patientInfo.address.state')
    // if(acceptedJurisdictions && acceptedJurisdictions.length > 0 && country && state && !acceptedJurisdictions.find(jurisdiction => jurisdiction.country === country && jurisdiction.states.includes(state))){
    //   acceptJurisdictionWarning.open()
    //   return
    // }
    
    formRef.current.willSubmitForm()
      
    
  }

  const userInfo = lazyUserInfo.read()
  const providerInfo = lazyProviderInfo.read()

  const careQualityEnabled = providerInfo?.carequalityEnabled && !intl.locale.indexOf("LEGAL") >= 0
  
  const saveCase = (data) => jwtPostFetcher(getJwt())('/api/admin/caseRequest', intl.locale.indexOf("LEGAL") >= 0 ? createLegalCaseTransform(data) : createCaseTransform(data))
  const onSuccess = (request,{requestCode, requestId}) => {

    if(careQualityEnabled){

      const birthDate = (() => {
        const dob = request?.patientInfo?.dob; // Assuming dob is in a proper string format like "YYYY-MM-DD"
        if (!dob) return null; // Handle null or undefined
        try {
            // Ensure the date format is compatible across all browsers
            const parsedDate = new Date(dob.replace(/-/g, '/')); // Replace hyphens with slashes for Safari compatibility
            return parsedDate.toISOString().split('T')[0];
        } catch (error) {
            console.error('Invalid Date Format:', error);
            return null;
        }
      })();

      const givenName = request?.patientInfo?.firstName.split(' ').filter(m => m.length > 0)
        .concat(request?.patientInfo?.middleName ? request.patientInfo.middleName.split(' ').filter(m => m.length > 0) : []);

      const getStateCode = (state, country) => {
        if(!state) return null
        if(!country) return state
        try{
          if(country === 'US' || country === 'USA' || country === 'United States' || country === 'United States of America'){
            return CountryRegionData
              .find(country => {
                return country[1] === 'US';
              })[2]
              .split("|")
              .find(st => st.split("~")[0] === state).split("~")[1];
          }else{
    
            return CountryRegionData
              .find(c => {
                return c[0] === country;
              })[2]
              .split("|")
              .find(st => st.split("~")[0] === state).split("~")[1];
          }
        }catch(e){
          return state
        }
      }
      
      const params = {
        zipCode: request?.patientInfo?.address?.zipCode,
        radius: 15,
        organizationName: null,
        patientDemographics: {
          familyName: request?.patientInfo?.lastName,
          givenName: givenName,
          // givenName: request?.patientInfo?.firstName.split(' ').filter(m => m.length > 0).concat(request?.patientInfo?.middleName?.split(' ').filter(m => m.length > 0).filter(m => m != null)),
          gender: convertToCareQualityGender(request?.patientInfo?.gender),
          // birthDate: new Date(request?.patientInfo?.dob).toISOString().split('T')[0],
          birthDate: birthDate,
          addressLine: request?.patientInfo?.address?.address,
          city: request?.patientInfo?.address?.city,
          state: getStateCode(request?.patientInfo?.address?.state, request?.patientInfo?.address?.country),
          postalCode: request?.patientInfo?.address?.zipCode,
          country: request?.patientInfo?.address?.country
        } 
      }

      jwtPostFetcher(getJwt())(`/api/carequality/queryForDocuments?requestId=${requestId}`, params)
    }

    // Fullstory disabled for now
    // var now = new Date()
    // var eventProperties = {
    //   expertName_str: userInfo.firstName + ' ' + userInfo.lastName,
    //   expertMail_str: userInfo.login,
    //   expertId_int: userInfo.id,
    //   customer_str: providerInfo.name,
    //   dateTime_date: now,
    //   requestId_int: requestId,
    //   caseId_str: requestCode
    // }
    // window.FS.event('Admin Case Created',eventProperties)
    navigate(`/admin/request/${requestCode}/`)
  }

  const confirmMissingCategoriesDialog = useConfirmDialogWithProps( data =>
    <ConfirmDialog
      title="Confirm case division(s)"
      action={() => saveCase(data)}
      onSuccess={onSuccess}
      confirm={<Button alert>Confirm & lose access to case</Button>}
    >
    <p>
      If you don't include at least one division you have access to, <strong>you will not be able to access this case. </strong>
      Consider keeping at least one division you have access to.
    </p>
    <p>Are you sure you don't want to include a division you can access?</p>
  </ConfirmDialog>)

  const action = (data) => {
    const newCategories = data.categories?.map(_.get('id'))
    if (!userInfo.categories.length || userInfo.categories.find(cat => newCategories?.indexOf(cat.id)>=0)) {
      return saveCase(data)
    } else {
      confirmMissingCategoriesDialog.openWith(data)
      return Promise.reject([])
    }
  }





  return <PageWrapper responsiveWorkaround>
    <CreateRequestSidebar caseRequest={{categories:categories.isOpen,markedAsPriority:markedAsPriority.isOpen}}
                          setCaseRequest={(update) => formRef.current.setValues(update)} />
    <PageContent>
      <PageContainer>
        <Header title="Create case">
        </Header>
        <PatientAndContactDetails
          action={action}
          formRef={formRef}
          onSuccess={onSuccess}
          hideErrors
          isAdmin={true}
          acceptedJurisdictions={acceptedJurisdictions}
          onChange={(prev, next) => {
            if (prev.markAsPriority !== next.markAsPriority) {
              markedAsPriority.openWith(next.markAsPriority)
            }
            if (prev.categories !== next.categories) {
              categories.openWith(next.categories)
            }
          }}
        >{form => <>
          <Errors/>
          <PageFooter>
            {/* <Button highlight isLoading={form.isLoading} type="submit">Create case</Button> */}
            <Button highlight isLoading={form.isLoading} onClick={accept}>Create case</Button>
          </PageFooter>
          </>
        }</PatientAndContactDetails>
      </PageContainer>
      {/* {acceptJurisdictionWarning.isOpen && <Dialog
        title="Attention"
        closeDialog={acceptJurisdictionWarning.close}
        footer={<> 
          <Button onClick={acceptJurisdictionWarning.close}>Cancel</Button>
          <Button highlight onClick={() => {
            acceptJurisdictionWarning.close()
            formRef.current.willSubmitForm()
            }}>Proceed</Button>
        </>}
      >
        <p><b>{formRef.current.getValue('patientInfo.address.state')} - {formRef.current.getValue('patientInfo.address.country')}</b> is not within your accepted jurisdictions.</p>
        <p>Do you want to proceed?</p>
      </Dialog>} */}
    </PageContent>
  </PageWrapper>
}

