import React from 'react'
import {FormValue, TextInput, useFormValue} from '@startlibs/form'
import {useConstant} from '@startlibs/core'

export const ConfirmEmailInput = ({confirmingPath, disabled, mandatory = true, canLock = true,   ...rest}) => {
  const [value] = useFormValue(confirmingPath)
  const originalValue = useConstant(value)
  
  const locked = disabled || !!originalValue && value === originalValue && canLock

  return <TextInput
      key={locked+""}
      locked={locked}
      raw={locked}
      value={locked ? value : undefined}
      type="text"
      label="Confirm email"
      placeholder="Ex: your@email.com"
      maxLength={255}
      mandatory={mandatory}
      {...rest}
    />
}